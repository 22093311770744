.information,
.warning {
  font-family: $font-family;
  font-size: $font-size;
  border: 1px solid;
  border-radius: 8px;
  margin: 5px 5px 30px;
  vertical-align: top;
  padding: 10px 10px 10px 50px;
  min-height: 28px;
  position: relative;
  text-align: center;
}

.information > .message-icon,
.warning > .message-icon {
  @include icon-font();
  @include icon-size($size: 32px);

  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -18px;
  margin-left: 16px;
  height: 32px;
}

.information > .message-icon {
  @include icon('info');
  @include icon-color($icon-information-color);
}

.warning > .message-icon {
  @include icon('warning');
  @include icon-color($icon-warning-color);
}

.warning {
  color: $warning-color;
  border-color: $warning-border-color;
  background: $warning-background-color;
}

.information {
  color: $text-color;
  border-color: $information-border-color;
  background: $information-background-color;
}
