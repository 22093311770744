.ext-shim {
  position: absolute;
  visibility: hidden;
  left: 0;
  top: 0;
  overflow: hidden;
}

.x-hidden,
.x-hide-offsets {
  position: absolute !important;
  left: -10000px;
  top: -10000px;
  visibility: hidden;
}

.x-hide-display {
  display: none !important;
}

.x-hide-nosize {
  height: 0 !important;
  width: 0 !important;
  visibility: hidden !important;
  border: 0 !important;
  zoom: 1;

  * {
    height: 0 !important;
    width: 0 !important;
    visibility: hidden !important;
    border: 0 !important;
    zoom: 1;
  }
}

.x-layer,
.x-hide-visibility {
  visibility: hidden;
}

.x-repaint {
  zoom: 1;
  background-color: transparent;
  outline: none;
}

.loading-indicator {
  background: no-repeat left;
  padding-left: 20px;
  line-height: 16px;
  display: inline-block;
  margin: 3px;
  font-size: $form-help-font-size;
  background-image: url('~grid/loading.gif');
}

.x-clear {
  clear: both;
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
}

.x-spotlight {
  z-index: 8999;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 0;
  height: 0;
  zoom: 1;
  background-color: $form-border-color;
}

#x-history-frame {
  position: absolute;
  top: -1px;
  left: 0;
  width: 1px;
  height: 1px;
  visibility: hidden;
}

#x-history-field {
  position: absolute;
  top: 0;
  left: -1px;
  width: 1px;
  height: 1px;
  visibility: hidden;
}

#spacer {
  height: 25px;
  float: left;
  width: 0;
  overflow: hidden;
  margin-top: 2px;
}

.x-panel-body p {
  padding: 10px;
  margin: 0;
}

.x-layer {
  text-align: left;

  a:hover {
    background-color: transparent;
  }
}

.muted {
  font-weight: normal;
}

.helptext {
  @include icon-font();
  @include icon-size(16px);
  @include icon('help');
  @include icon-color($icon-help-color);

  display: inline-block;
  vertical-align: top;
  height: 22px;
  margin: 0 0 0 4px;
  width: 16px;

  &::before {
    cursor: default;
  }
}

.help {
  em {
    background-color: $panel-border-color;
    padding: 3px;
  }

  .tm {
    font-style: italic;
  }
}

.smx-live-search-results {
  .x-combo-list-item {
    line-height: 1.4;
  }

  h3.group-header {
    padding: 8px 2px 0;
  }
}

.invoice-list-panel {
  .ready {
    color: $icon-success-color;
  }

  .cancelled {
    color: #777;
  }

  .sent {
    color: $icon-help-color;
    font-style: italic;
  }

  strong {
    font-weight: bold;
  }

  &.smx-side-resource {
    border-left: 1px $panel-border-color solid;
  }
}

.graph-fail {
  position: absolute;
  background: $panel-background-color;
  border: 1px $button-border-color solid;
  padding: 10px;
}

.x-form-item-dirty .x-form-item-label,
.x-tab-strip .x-tab-dirty span.x-tab-strip-text {
  font-style: italic;
}

.provision-status {
  padding: 5px;
}

.provision-status-list {
  margin: 0 0 0 20px;
  list-style: disc;
}

.wildcard-button-remove {
  padding-left: 23px !important;
}

.xAxis .tickLabel {
  width: 47px;
  text-align: center;
}

.note-list-panel {
  .x-grid3-col-noteDate,
  .x-grid3-col-party {
    font-weight: bold;
  }

  .x-grid3-row-body p {
    padding: 3px 3px 3px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $text-color;
  }
}

fieldset.mail-search-tags {
  legend {
    font-weight: normal;
    font-size: $font-size;
  }

  .x-fieldset-bwrap {
    margin-left: 19px;
  }
}
