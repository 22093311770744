.timeZone {
  .select__control {
    width: 140px;
    border-color: $form-border-color;
    box-shadow: none;
    height: $button-text-height;
    min-height: $button-text-height;
    margin-right: -1px;

    &:hover {
      border-color: $form-text-focus-border-color;
      z-index: 10000; // ensures border isn't hidden by any other surrounding inputs

      .select__dropdown-indicator {
        color: $form-text-focus-border-color;
      }
    }

    .select__indicator-separator {
      display: none;
    }

    .select__dropdown-indicator {
      padding: 0;
      color: $form-border-color;
    }
  }

  .select__menu {
    margin: 0 0 8px;
    box-shadow: none;
    z-index: 1000;
    border: 1px $form-text-border-color solid;

    &:hover {
      border-color: $form-text-focus-border-color;
    }

    .select__menu-list {
      padding: 0;
    }

    .select__option--is-focused {
      background-color: $grid-row-over-background-color;
    }

    .select__option--is-selected {
      background-color: $grid-selected-row-background-color;
      color: inherit;
    }
  }
}
