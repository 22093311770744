.glyphicon {
  font-family: $icon-font-family;
  font-style: normal;
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-ok {
  @include icon('checkmark-circle');
}

.glyphicon-warning-sign {
  @include icon('warning');
}

.play,
.blocked,
.next-refresh,
.icon-confirm,
.icon-cancel,
.icon-reset,
.icon-csv,
.icon-upgrade,
.icon-search,
.icon-password {
  &::before {
    /* Keep line height small so icon can be large without stretching button */
    line-height: $font-size;
    font-family: $icon-font-family;
  }

  &:not(.btn-icon)::before {
    /* that's the margin between icon and text for those non-icon-only-buttons */
    margin-right: 5px;
  }
}

.btn-primary {
  svg {
    fill: $button-primary-font-color;
  }
}

.btn {
  svg {
    width: $button-icon-font-size;
    height: $button-icon-font-size;
    margin-right: $padding-large-vertical;
    vertical-align: middle;
  }
}

.btn-default {
  svg {
    fill: $btn-default-color;
  }
}
