/*
 * Normal
 */

$tab-strip-background-color: $neutral-color !default;
$tab-strip-background-color-ie: mix(
  $neutral-color,
  darken($tab-strip-background-color, $gradient-darkness-diff)
) !default;
$tab-strip-background: darken(
  $tab-strip-background-color,
  $gradient-darkness-diff
) !default;

$tab-strip-bottom-border-color: darken(
  $tab-strip-background-color,
  $border-darkness-diff
) !default;
$tab-strip-top-border-color: $tab-strip-bottom-border-color;

$tab-background-color: adjust-color($neutral-color, $lightness: -3%, $hue: 1deg) !default;
$tab-background: linear-gradient(
  to bottom,
  $tab-background-color,
  darken($tab-background-color, $gradient-darkness-diff)
) !default;
$tab-font-color: darken(opposite-color($tab-background-color), 32) !default;
$tab-border-color: darken($tab-background-color, $border-darkness-diff) !default;
$tab-border: 1px $tab-border-color solid !default;
$tab-border-radius: 3px !default;

$tab-hover-background-color: lighten(
  $tab-background-color,
  $hover-lightness-diff
) !default;
$tab-hover-background-color-ie: mix(
  $tab-background-color,
  darken($tab-hover-background-color, $gradient-darkness-diff),
  70
) !default;
$tab-hover-background: darken(
  $tab-hover-background-color,
  $gradient-darkness-diff
) !default;
$tab-hover-font-color: darken(opposite-color($tab-hover-background-color), 48) !default;

$tab-active-background-color: lighten(
  $tab-hover-background-color,
  $active-lightness-diff
) !default;
$tab-active-background-color-ie: mix(
  $tab-active-background-color,
  darken($tab-active-background-color, $gradient-darkness-diff),
  70
) !default;
$tab-active-background: darken(
  $tab-active-background-color,
  $gradient-darkness-diff
) !default;
$tab-active-font-color: darken(opposite-color($tab-active-background-color), 48) !default;

/*
 * Large e.g. Products
 */

$tab-large-strip-background-color: $base-color !default;
$tab-large-strip-background: linear-gradient(
  to bottom,
  $tab-large-strip-background-color,
  darken($tab-large-strip-background-color, 10)
) !default;

$tab-large-border-radius: $tab-border-radius !default;

$tab-large-background-color: adjust-color(
  $tab-large-strip-background-color,
  $lightness: 8%
) !default;
$tab-large-background-color-ie: mix(
  $tab-large-background-color,
  darken($tab-large-background-color, 16),
  70
) !default;
$tab-large-background: linear-gradient(
  to bottom,
  $tab-large-background-color,
  darken($tab-large-background-color, 16)
) !default;
$tab-large-font-color: opposite-color($tab-large-background-color) !default;
$tab-large-font-weight: bold !default;
$tab-large-border-color: darken($tab-large-background-color, 15) !default;
$tab-large-border: 1px $tab-large-border-color solid !default;
$tab-large-icon-color: $tab-large-font-color !default;

$tab-large-hover-background-color: darken($tab-large-background-color, 8) !default;
$tab-large-hover-background-color-ie: mix(
  $tab-large-hover-background-color,
  darken($tab-large-hover-background-color, 16),
  70
) !default;

$tab-large-hover-background: $tab-large-hover-background-color !default;

@if $use-gradients {
  $tab-large-hover-background: linear-gradient(
    to bottom,
    $tab-large-hover-background-color,
    darken($tab-large-hover-background-color, $hover-lightness-diff)
  ) !default;
}

$tab-large-hover-font-color: opposite-color($tab-large-hover-background-color) !default;
$tab-large-hover-font-weight: bold !default;
$tab-large-hover-border-color: darken($tab-large-hover-background-color, 30) !default;
$tab-large-hover-border: 1px $tab-large-hover-border-color solid !default;
$tab-large-hover-icon-color: $tab-large-hover-font-color !default;

$tab-large-active-background-color: lighten(
  $tab-large-hover-background-color,
  $active-lightness-diff
) !default;
$tab-large-active-background: $tab-large-active-background-color !default;
$tab-large-active-font-color: opposite-color($tab-large-active-background-color) !default;
$tab-large-active-font-weight: bold !default;
$tab-large-active-border-color: darken($tab-large-active-background-color, 20) !default;
$tab-large-active-border: 1px $tab-large-active-border-color solid !default;
$tab-large-active-icon-color: $tab-large-active-font-color !default;

$tab-large-band-expired-icon-color: #eeb !default;
$tab-large-band-icon-shadow-color: #666 !default;
