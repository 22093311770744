#center-login,
#center-login-unavailable {
  background: $login-background;

  .x-panel-body {
    background-color: transparent;
  }

  .reset-link,
  .retry-button {
    text-align: center;
  }

  @if $powered-by == true {
    > div > .x-panel-body {
      background-image: url('~powered-by.png');
      background-position: 97% 97%;
      background-repeat: no-repeat;
      background-size: 180px;
    }
  }
}

#retry-panel {
  position: relative;

  h1,
  p {
    color: $text-color;
    padding: 0;
  }

  h1,
  p,
  button {
    text-align: center;
  }

  h1 {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .retry-button {
    margin: 10px auto 20px;
  }

  p {
    font-size: 11px;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

#login-form {
  .login-button {
    margin: $button-text-height auto;
  }

  .next-link a {
    color: $form-label-color;
  }
}
