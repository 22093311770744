.x-list-header {
  background: url('~grid/grid3-hrow2.gif') $grid-header-background-color repeat-x 0 bottom;
  cursor: default;
  height: 22px;
}

.x-list-header-inner div {
  display: block;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  em {
    display: block;
    border-left: 1px solid;
    padding: 4px;
    overflow: hidden;
    -moz-user-select: none;
    line-height: 14px;

    &.sort-asc,
    &.sort-desc {
      background-image: url('~grid/sort-hd.gif');
      border-color: $panel-border-color;
      border-style: solid;
      border-width: 0 1px 1px;
      padding-bottom: 3px;
    }

    &.sort-asc {
      background: transparent no-repeat center 0;
    }

    &.sort-desc {
      background: transparent no-repeat center -23px;
    }
  }

  div em {
    border-left-color: #ddd;
  }
}

.x-list-body {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
  float: left;
  width: 100%;

  dt {
    display: block;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;

    em {
      display: block;
      padding: 3px 4px;
      overflow: hidden;
      -moz-user-select: none;
    }
  }
}

.x-list-resizer {
  border-left: 1px $form-label-color solid;
  border-right: 1px $form-label-color solid;
  position: absolute;
  left: 0;
  top: 0;
}

.x-list-body dt em,
.x-list-header-inner div em {
  font-size: $font-size;
}

.x-list-over {
  background-color: $neutral-color;
}

.x-list-selected {
  background-color: $toolbar-button-hover-background;
}
