.x-panel-noborder {
  .x-panel-body-noborder {
    border-width: 0;
  }

  @if $toolbar-background-color != transparent {
    .x-panel-header-noborder,
    .x-panel-tbar-noborder .x-toolbar {
      border-width: 0 0 1px;
      border-style: solid;
      border-bottom-color: $panel-border-color;
    }

    .x-panel-bbar-noborder .x-toolbar {
      border-width: 1px 0 0;
      border-style: solid;
      border-top-color: $panel-border-color;
    }
  }
}

.x-tab-panel-noborder {
  .x-tab-panel-body-noborder {
    border-width: 0;
  }

  .x-tab-panel-header-noborder {
    border-width: 0 0 1px;
  }

  .x-tab-panel-footer-noborder {
    border-width: 1px 0 0;
  }
}

.x-tab-panel-bbar-noborder .x-toolbar {
  border-width: 1px 0 0;
  border-style: solid;
  border-top-color: $panel-border-color;
}

.x-tab-panel-tbar-noborder .x-toolbar {
  border-width: 0 0 1px;
  border-style: solid;
  border-bottom-color: $panel-border-color;
}

.x-border-layout-ct {
  background-color: $desktop-background;
  position: relative;
}

.x-border-panel {
  position: absolute;
  left: 0;
  top: 0;
}
