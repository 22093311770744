// General (= applies to all button types)

$button-border-radius: 3px !default;
$button-text-height: 26px !default;
$button-font-size: $font-size !default;
$button-icon-font-size: 16px !default;

$button-min-width: 70px;
$button-padding-x: 8px;
$button-text-transform: inherit !default;
$button-flat: false !default;
$button-ripple: false !default;

// Primary buttons

$button-primary-background-color: lighten($neutral-color, 8) !default;
$button-primary-background: $button-primary-background-color;

$button-primary-font-color: opposite-color($button-primary-background-color) !default;

$button-primary-font-weight: bold !default;
$button-primary-border-color: darken(
  $button-primary-background-color,
  $border-darkness-diff
) !default;
$button-primary-border: 1px $button-primary-border-color solid !default;
$button-primary-icon-color: $button-primary-font-color !default;

// Primary buttons HOVER

$button-primary-hover-background-color: lighten(
  $button-primary-background-color,
  8
) !default;
$button-primary-hover-background: $button-primary-hover-background-color !default;
$button-primary-hover-font-color: #fff !default;

// Primary buttons DISABLED

$button-primary-disabled-font-color: adjust-color(
  $button-primary-font-color,
  $saturation: -64%
) !default;
$button-primary-disabled-border-color: $button-primary-border-color !default;
$button-primary-disabled-border: 1px $button-primary-disabled-border-color solid !default;
$button-primary-disabled-background-color: $button-primary-background-color !default;
$button-primary-disabled-background: $button-primary-disabled-background-color !default;

// Primary buttons ACTIVE (only used for some brands when there is no ripple effect)

$button-primary-active-background-color: lighten(
  $button-primary-hover-background-color,
  6
) !default;
$button-primary-active-border-color: darken(
  $button-primary-active-background-color,
  2 * $border-darkness-diff
) !default;
$button-primary-active-border: 1px $button-primary-active-border-color solid !default;

// Secondary buttons

$button-background-color: #fff !default;
$button-background: $button-background-color !default;

$button-font-color: opposite-color($button-background-color) !default;

$button-font-weight: bold !default;
$button-border-color: darken($button-background-color, $border-darkness-diff) !default;
$button-border: 1px $button-border-color solid !default;
$button-icon-color: $button-font-color !default;
$button-hover-icon-color: $button-hover-font-color !default;

// Secondary buttons DISABLED

$button-disabled-font-color: $button-primary-disabled-font-color !default;
$button-disabled-border-color: $button-border-color !default;
$button-disabled-border: 1px $button-disabled-border-color solid !default;
$button-disabled-background-color: $button-primary-disabled-background-color !default;
$button-disabled-background: darken(
  $button-disabled-background-color,
  $gradient-darkness-diff
) !default;

// Secondary buttons HOVER

$button-hover-background-color: lighten(
  $button-background-color,
  $hover-lightness-diff
) !default;
$button-hover-background: linear-gradient(
  to bottom,
  $button-hover-background-color,
  darken($button-hover-background-color, $gradient-darkness-diff)
) !default;

$button-hover-font-color: darken(
  opposite-color($button-hover-background-color),
  16
) !default;

$button-hover-font-weight: bold !default;
$button-hover-border-color: darken(
  $button-hover-background-color,
  2 * $border-darkness-diff
) !default;
$button-hover-border: 1px $button-hover-border-color solid !default;
$button-hover-icon-color: $button-hover-font-color !default;

// Secondary buttons ACTIVE

$button-active-background-color: lighten($button-hover-background-color, 5) !default;
$button-active-font-color: opposite-color($button-active-background-color) !default;
$button-active-border-color: darken(
  $button-active-background-color,
  2 * $border-darkness-diff
) !default;
$button-active-border: 1px $button-active-border-color solid !default;
$button-active-icon-color: $button-active-font-color !default;

// Toolbar buttons

$toolbar-button-background: $button-background !default;
$toolbar-button-border-color: $button-border-color !default;
$toolbar-button-hover-background: $button-hover-background !default;

// Modal window tool buttons

$modal-tool-button-background: $button-background !default;
$modal-tool-button-icon-color: lighten($button-icon-color, 15) !default;
$modal-tool-button-hover-icon-color: $modal-tool-button-icon-color !default;
