.x-date-picker {
  em {
    font-style: normal;
  }
}

.x-date-menu {
  background-color: $date-picker-background-color;
  font-family: $font-family;
  font-size: $button-font-size;
  padding: 0 4px 4px;

  a {
    outline: 0 none;
  }

  .x-date-middle a,
  .x-date-left a,
  .x-date-right a,
  .x-date-mp-btns button,
  .x-date-mp-ybtn a {
    @include default-button;
  }

  .x-date-left,
  .x-date-right,
  .x-date-mp-ybtn {
    line-height: $button-icon-font-size;
    width: $button-icon-font-size;

    a {
      display: block;
      width: $button-icon-font-size;
      height: $button-icon-font-size;
      opacity: 0.6;
      padding: 5px;

      &:hover {
        opacity: 1;
      }
    }
  }

  .x-item-disabled {
    .x-date-left,
    .x-date-right {
      a:hover {
        opacity: 0.6;
      }
    }

    .x-date-inner a:hover {
      background: none;
    }
  }

  .x-date-left {
    text-align: right;
  }

  .x-date-middle {
    overflow: hidden;
    padding: 4px 0;

    .x-btn-mc em.x-btn-arrow {
      background: transparent no-repeat right 0;
      background-image: url('~button/btn-arrow-light.png');
    }
  }

  .x-btn:not(.primary) {
    @include secondary-button(true);

    background: $date-picker-button-background-color;
  }

  .x-date-bottom {
    padding-top: 6px;
  }
}

table.x-date-inner th,
table.x-date-inner td a,
.x-date-mp td.x-date-mp-month a,
.x-date-mp td.x-date-mp-year a {
  color: $date-picker-table-button-font-color;
  padding: 7px;
  text-align: center;
  border-radius: $button-border-radius;
}

table.x-date-inner td a,
.x-date-mp td.x-date-mp-month a,
.x-date-mp td.x-date-mp-year a {
  display: block;
  border: 1px transparent solid;
}

table.x-date-inner td.x-date-selected a,
.x-date-mp td.x-date-mp-month.x-date-mp-sel a,
.x-date-mp td.x-date-mp-year.x-date-mp-sel a {
  color: $date-picker-selected-font-color;
  background: $date-picker-selected-background-color;

  span {
    font-weight: bold;
  }

  &:hover {
    background: $button-primary-hover-background;
    color: $date-picker-selected-font-color;
  }
}

table.x-date-inner {
  .x-date-nextday,
  .x-date-prevday {
    a {
      opacity: 0.4;
    }
  }

  a.x-date-date:hover {
    color: $button-hover-font-color;
    background-color: $button-hover-background-color;
  }

  .x-date-disabled a {
    &,
    &:hover {
      background: transparent;
      color: $date-picker-disabled-font-color;
      cursor: default;
    }
  }
}

.x-date-mp {
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  background-color: $date-picker-background-color;

  &,
  table {
    width: 100% !important;
    height: 100% !important;
  }

  td {
    &.x-date-mp-month,
    &.x-date-mp-year {
      padding-left: 6px;
      padding-right: 6px;

      a {
        display: block;
        text-decoration: none;

        &:hover {
          color: $button-hover-font-color;
          background-color: $button-hover-background-color;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    &.x-date-mp-sep {
      border-right: 1px #d7d7d7 solid;
    }
  }

  .x-date-mp-btns {
    td {
      text-align: center;
      direction: rtl; // because we can't override extjs code to rearrange buttons
    }

    .x-date-mp-ok {
      margin-left: 4px;
    }
  }
}
