.x-combo-list-inner {
  overflow: auto;
  position: relative;

  /* for calculating scroll offsets */
  zoom: 1;
  overflow-x: hidden;
  background-color: $form-text-background-color;
}

.x-combo-list-hd {
  border-bottom: 1px darken($combo-list-background-color, 15%) solid;
  padding: 3px;
  font-weight: bold;
  font-size: $font-size;
  font-family: $font-family;
  color: $text-color;
  background: url('~panel/panel-title-light-bg.png');
}

.x-resizable-pinned .x-combo-list-inner {
  border-bottom: 1px darken($combo-list-background-color, 15%) solid;
}

.x-combo-list-item {
  padding: 2px;
  border: 1px $form-text-background-color solid;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.x-combo-list {
  border: 1px $form-border-color solid;
  overflow: hidden;
  background-color: $combo-list-background-color;
  font-size: $font-size;
  font-family: $font-family;

  .x-combo-selected {
    border: 1px $form-text-focus-border-color dotted !important;
    cursor: pointer;
    background-color: $grid-row-over-background-color;
  }

  .x-toolbar {
    border-top: 1px darken($combo-list-background-color, 15%) solid;
  }
}

.x-combo-list-small {
  font-size: $font-size;
  font-family: $font-family;
}

.readonly-combo {
  color: $form-input-text-color !important;
  border-width: 1px;
  border-color: transparent;
  background: none;
}

.readonly-combo-trigger {
  border-width: 1px;
  border-color: transparent;
  background: none;
}
