@mixin smartrules-icon-frame {
  padding: 2px 5px;
  padding-top: 2px;
  display: inline-block;
  transition-property: color, background, background-color, fill;
  transition-duration: 0.5s;
}

@mixin smartrules-svg-icon-common() {
  // this to select those in the icon bar only
  > .x-panel-bwrap {
    svg {
      @include smartrules-icon-frame;

      fill: $smartrules-icon-color;
      padding-top: 4px;
    }
  }
}

@mixin smartrules-condition-icons {
  .smartrules-headers.smartrules-icon,
  .smartrules-lists.smartrules-icon,
  .smartrules-suspected-whaling.smartrules-icon {
    @include smartrules-svg-icon-common;
  }

  .smartrules-addressed-from.smartrules-icon {
    @include icon('smartrules-condition-sender');
  }

  .smartrules-addressed-to.smartrules-icon {
    @include icon('smartrules-condition-recipient');
  }

  .smartrules-attachment.smartrules-icon {
    @include icon('smartrules-condition-attachments');
  }

  .smartrules-condition-group.smartrules-icon {
    @include icon('smartrules-condition-group');
  }

  .smartrules-message.smartrules-icon {
    @include icon('smartrules-condition-content');
  }

  .smartrules-size-of.smartrules-icon {
    @include icon('smartrules-condition-message-size');
  }
}

@mixin smartrules-action-icons {
  .smartrules-quarantine.smartrules-icon,
  .smartrules-prepend-preamble.smartrules-icon,
  .smartrules-append-footer.smartrules-icon {
    @include smartrules-svg-icon-common;
  }

  .smartrules-bcc-to.smartrules-icon {
    @include icon('smartrules-action-bcc');
  }

  .smartrules-cc-to.smartrules-icon {
    @include icon('smartrules-action-cc');
  }

  .smartrules-delete-message.smartrules-icon {
    @include icon('remove');
  }

  .smartrules-redirect-to.smartrules-icon {
    @include icon('smartrules-action-redirect');
  }

  .smartrules-refuse-message.smartrules-icon {
    @include icon('spam');
  }

  .smartrules-replace-domain.smartrules-icon {
    @include icon('smartrules-action-replace-matched-recipient-domains');
  }

  .smartrules-replace-sender.smartrules-icon {
    @include icon('smartrules-action-replace-sender');
  }

  .smartrules-whitelist.smartrules-icon {
    @include icon('checkmark');
  }

  .smartrules-send-notification.smartrules-icon {
    @include icon('smartrules-action-notification');
  }

  .smartrules-stop-processing.smartrules-icon {
    @include icon('last');
  }

  .smartrules-strip-all-attachments.smartrules-icon {
    @include icon('smartrules-action-remove-all-attachments');
  }

  .smartrules-strip-matched-attachments.smartrules-icon {
    @include icon('smartrules-action-remove-matched-attachments');
  }

  .smartrules-action-group.smartrules-icon {
    @include icon('smartrules-condition-group');
  }
}
