.x-dd-drag-proxy {
  position: absolute;
  left: 0;
  top: 0;
  visibility: hidden;
  z-index: 15000;
}

.x-dd-drag-ghost {
  position: absolute;
  opacity: 0.75;
  border: 1px solid;
  padding: 5px 6px 5px 26px;
  white-space: nowrap;
  color: $icon-button-and-menu-color;
  font-size: $font-size;
  background-color: $toolbar-button-background;

  .x-dd-drop-nodrop & {
    background-color: $icon-warning-color;
  }

  .x-dd-drop-add &,
  .x-dd-drop-ok & {
    background-color: $icon-success-color;
  }
}

.x-dd-drag-repair {
  .x-dd-drag-ghost {
    opacity: 0.4;
    border: 0;
    padding: 0;
    background-color: transparent;
  }

  .x-dd-drop-icon {
    visibility: hidden;
  }
}

.x-dd-drop-icon {
  @include icon-font();
  @include icon-size($button-icon-font-size);
  @include icon-color($icon-button-and-menu-color);

  position: absolute;
  top: 0;
  left: 5px;
  display: block;
  width: $button-icon-font-size;
  height: $button-icon-font-size;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;

  &::before {
    display: block;
    position: absolute;
    top: 50%;
    height: $button-icon-font-size;
    margin-top: -5px;
  }
}

.x-view-selector {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  border: 1px dotted;
  opacity: 0.5;
  zoom: 1;
  background-color: $button-hover-background-color;
  border-color: $button-border-color;
}

.x-dd-drop-nodrop .x-dd-drop-icon {
  @include icon('blocked');
}

.x-dd-drop-ok .x-dd-drop-icon {
  @include icon('checkmark-circle');
}

.x-dd-drop-ok-add .x-dd-drop-icon {
  @include icon('plus');
}

.x-drag-overlay {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  background: extImage('s');
  z-index: 20000;
}
