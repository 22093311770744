.x-tab-scroller-left,
.x-tab-scroller-right {
  border-bottom: 1px $panel-border-color solid;
  width: 18px;
  position: absolute;
  top: 0;
  z-index: 10;
  cursor: pointer;
}

.x-tab-scroller-left {
  background: url('~tabs/scroll-left.png') transparent no-repeat -18px 0;
  left: 0;
}

.x-tab-scroller-left-over {
  background-position: 0 0;
}

.x-tab-scroller-left-disabled {
  background-position: -18px 0;
  opacity: 0.5;
  cursor: default;
}

.x-tab-scroller-right {
  background: url('~tabs/scroll-right.png') transparent no-repeat 0 0;
  right: 0;
}

.x-tab-scroller-right-over {
  background-position: -18px 0;
}

.x-tab-scroller-right-disabled {
  background-position: 0 0;
  opacity: 0.5;
  cursor: default;
}

.x-tab-scrolling-bottom {
  .x-tab-scroller-left,
  .x-tab-scroller-right {
    margin-top: 1px;
  }
}

.x-tab-scrolling {
  position: relative;

  .x-tab-strip-wrap {
    margin-left: 18px;
    margin-right: 18px;
  }
}
