.smx-domain-panel {
  .x-form {
    padding: 5px 5px 0;
  }

  .x-form-check-wrap {
    font-size: $font-size;
    font-family: $font-family;
    line-height: $form-input-line-height;
    padding: 0 0 8px;
  }

  .smx-domain-grid {
    margin: 0 0 5px;

    .x-grid3-header {
      border-top: 1px $panel-border-color solid;
      border-left: 1px $panel-border-color solid;
      border-right: 1px $panel-border-color solid;
    }
  }

  .attributes .x-form-check-wrap {
    padding: 4px 10px;
  }
}
