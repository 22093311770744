.x-tab-strip {
  a,
  em,
  span {
    display: block;
  }

  em {
    font-style: normal;
  }

  a {
    text-decoration: none !important;
    outline: none;
  }

  span.x-tab-strip-text {
    white-space: nowrap;
    padding: 6px 0;
  }

  .x-tab-with-icon span.x-tab-strip-text {
    padding-left: 20px;
    background-position: 0 3px;
    background-repeat: no-repeat;
  }

  .x-tab-strip-close {
    display: none;
  }

  .x-tab-strip-closable a.x-tab-strip-close {
    opacity: 0.6;
    display: block;
    width: 11px;
    height: 11px;
    position: absolute;
    top: 3px;
    right: 3px;
    cursor: pointer;
    z-index: 2;
    background: extImage('tab-close') no-repeat;

    &:hover {
      opacity: 1;
      background: extImage('tab-close');
    }
  }

  .x-tab-strip-active a.x-tab-strip-close {
    opacity: 0.8;
  }
}

.x-tab-strip-active {
  a.x-tab-right,
  span.x-tab-strip-text {
    cursor: default;
  }
}

.x-tab-strip-top {
  background-color: $tab-strip-background-color;

  .x-tab-right {
    padding-left: 12px;
  }

  .x-tab-left {
    padding-right: 12px;
  }

  .x-tab-strip-over span.x-tab-strip-text {
    color: $tab-hover-font-color;
  }

  .x-tab-strip-active .x-tab-right {
    background-position: 0 0;
    margin-bottom: -1px;

    span.x-tab-strip-text {
      padding-bottom: 7px;
    }
  }

  .x-tab-with-icon .x-tab-right {
    padding-left: 6px;
  }
}

.x-tab-strip-bottom {
  .x-tab-right {
    padding: 0;
  }

  .x-tab-left {
    margin-right: 3px;
    padding: 0 12px;
  }

  .x-tab-strip-active .x-tab-right {
    margin-top: -1px;

    span.x-tab-strip-text {
      padding-top: 5px;
    }
  }
}

.x-tab-strip-closable {
  position: relative;

  .x-tab-left {
    padding-right: 19px;
  }
}

ul {
  &.x-tab-strip-top {
    padding-top: 5px;
  }

  &.x-tab-strip-bottom {
    padding-bottom: 1px;
    background: repeat-x top;
    border-top: 1px $tab-strip-top-border-color solid;
    border-bottom: 0;
  }

  &.x-tab-strip {
    display: block;
    width: 5000px;

    li {
      border: $tab-border;
      float: left;
      margin-left: 3px;
      list-style-type: none;

      @if $tab-border-radius != 0 {
        border-top-left-radius: $tab-border-radius;
        border-top-right-radius: $tab-border-radius;
      }

      &.x-tab-edge {
        display: none;
      }

      span.x-tab-strip-text {
        font-weight: normal;
        font-size: $font-size;
        color: $tab-font-color;
      }

      &.x-item-disabled {
        opacity: 0.7;

        a {
          cursor: default;
        }
      }

      &:not(.x-item-disabled):hover &.x-tab-strip-over {
        background-color: $tab-hover-background-color;

        @if not $button-flat {
          background: $tab-hover-background;
        }

        span.x-tab-strip-text {
          color: $tab-hover-font-color;
        }
      }

      &.x-tab-strip-active:not(.x-item-disabled) {
        border-bottom-color: transparent;
        background-color: $tab-active-background-color;

        @if not $button-flat {
          background: $tab-active-background;
        }

        span.x-tab-strip-text {
          color: $tab-active-font-color;
          font-weight: bold;
        }
      }
    }
  }
}

.x-tab-strip-disabled .x-tabs-text {
  color: $button-disabled-font-color;
  cursor: default;
}

.x-tab-panel-header-plain {
  .x-tab-strip-top {
    background: transparent !important;
    padding-top: 0 !important;
  }

  .x-tab-strip-spacer {
    border-top: 0;
  }
}

.x-tab-strip-wrap {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.x-tab-strip-inner {
  overflow: hidden;
  text-overflow: ellipsis;
}

.ext-border-box {
  .x-tab-panel-footer-plain .x-tab-strip-spacer,
  .x-tab-panel-header-plain .x-tab-strip-spacer {
    height: 3px;
  }
}

.x-tab-panel-footer-plain {
  .x-tab-strip-bottom {
    background: transparent !important;
    padding-bottom: 0 !important;
  }

  .x-tab-strip-spacer {
    border-bottom: 0;
  }
}

.x-tab-panel-footer-plain .x-tab-strip-spacer,
.x-tab-panel-header-plain .x-tab-strip-spacer {
  background-color: $button-background-color;
  border: 1px $panel-border-color solid;
  height: 2px;
  font-size: 1px;
  line-height: 1px;
}
