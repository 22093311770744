.x-btn-group {
  padding: 1px;
}

.x-btn-group-header {
  padding: 2px;
  text-align: center;
}

.x-btn-group-tc {
  background: transparent repeat-x 0 0;
  overflow: hidden;
}

.x-btn-group-tl {
  background: transparent no-repeat 0 0;
  padding-left: 3px;
  zoom: 1;
}

.x-btn-group-tr {
  background: transparent no-repeat right 0;
  zoom: 1;
  padding-right: 3px;
}

.x-btn-group-bc {
  background: transparent repeat-x 0 bottom;
  zoom: 1;

  .x-panel-footer {
    zoom: 1;
  }
}

.x-btn-group-bl {
  background: transparent no-repeat 0 bottom;
  padding-left: 3px;
  zoom: 1;
}

.x-btn-group-br {
  background: transparent no-repeat right bottom;
  padding-right: 3px;
  zoom: 1;
}

.x-btn-group-mc {
  border: 0;
  padding: 1px 0 0;
  margin: 0;

  .x-btn-group-body {
    background-color: transparent;
    border: 0;
  }
}

.x-btn-group-ml {
  background: transparent repeat-y 0 0;
  padding-left: 3px;
  zoom: 1;
}

.x-btn-group-mr {
  background: transparent repeat-y right 0;
  padding-right: 3px;
  zoom: 1;
}

.x-btn-group-bc .x-btn-group-footer {
  padding-bottom: 6px;
}

.x-panel-nofooter .x-btn-group-bc {
  height: 3px;
  font-size: 0;
  line-height: 0;
}

.x-btn-group-body,
.x-btn-group-bwrap {
  overflow: hidden;
  zoom: 1;
}

.x-btn-group-notitle .x-btn-group-tc {
  background: transparent repeat-x 0 0;
  overflow: hidden;
  height: 2px;
}
