@use "sass:math";

// alert box (= each thrown app error gets logged into message log below)
// inherits most styles from tooltips.scss, see Alert.coffee (todo split markup + styles)

#alert-msg-div {
  z-index: 10000;
  width: 400px;

  > div {
    margin-bottom: math.div($qtip-font-size, 2);
  }

  // only want to see the datetime under the Message Log, not in the alert box
  .datetime {
    display: none;
  }
}

#message-log {
  .x-window-body {
    padding: 0 5px;

    .x-panel-body {
      margin-top: 5px;
    }
  }

  .delete {
    display: none;
  }
}
