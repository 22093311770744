.x-form-element {
  .strengthMeter {
    position: relative;
    border: 1px #b5b8c8 solid;
    margin: 3px 0;
    background-image: url('~password/meter_background.gif');
  }

  .strengthMeter-focus {
    border: 1px $form-input-text-color solid;
  }

  .scoreBar {
    background-image: url('~password/meter.gif');
    height: $button-icon-font-size;
    width: 0;
    line-height: 1px;
    font-size: 1px;
  }

  .barLabel {
    position: absolute;
    line-height: $button-icon-font-size;
    color: $legend-color;
    font-weight: bold;
    top: 0;
    left: 0;
    text-align: center;
  }
}
