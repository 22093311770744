.x-window-proxy {
  border: 1px $panel-border-color solid;
  z-index: 12000;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  display: none;
  opacity: 0.5;
  background-color: $mask-background-color;
}

.x-window-header,
.x-window-maximized-ct,
.x-window-body,
.x-window-bwrap {
  overflow: hidden;
}

.x-window-bwrap {
  z-index: 1;
  position: relative;
  left: 0;
  top: 0;
}

.x-window-tl .x-window-header {
  padding: 5px 0 4px;
}

.x-window-header-text {
  cursor: pointer;
}

.x-window-tc {
  background: transparent repeat-x 0 0;
  overflow: hidden;
}

.x-window-tl {
  padding-left: 5px;
  z-index: 1;
  position: relative;
}

.x-window-tr {
  padding-right: 5px;
}

.x-window-bc {
  background: transparent repeat-x 0 bottom;

  .x-window-footer {
    padding-bottom: 5px;
    font-size: 0;
    line-height: 0;
  }
}

.x-window-bl {
  background: transparent no-repeat 0 bottom;
  padding-left: 5px;
}

.x-window-br {
  background: transparent no-repeat right bottom;
  padding-right: 5px;
}

.x-window-mc {
  @if not $window-flat {
    border: 1px $window-border-color solid;
  }
}

@if not $window-flat {
  .x-window-ml {
    padding-left: 5px;
  }

  .x-window-mr {
    padding-right: 5px;
  }
}

.x-window-maximized {
  .x-window-bl,
  .x-window-br,
  .x-window-ml,
  .x-window-mr,
  .x-window-tl,
  .x-window-tr {
    padding: 0;
  }

  .x-window-footer {
    padding-bottom: 0;
  }

  .x-window-tc {
    padding-left: 3px;
    padding-right: 3px;
  }

  .x-window-mc {
    border-left: 0;
    border-right: 0;
  }
}

.x-window-tbar .x-toolbar,
.x-window-bbar .x-toolbar {
  border-left: 0;
  border-right: 0;
}

.x-window-bbar .x-toolbar {
  border-top: 1px solid;
  border-bottom: 0;
}

.x-window-draggable {
  cursor: move;

  .x-window-header-text {
    cursor: move;
  }
}

.x-window-maximized .x-window-draggable {
  cursor: default;

  .x-window-header-text {
    cursor: default;
  }
}

.x-window-body .help-block-large {
  padding: 10px 20px 20px;
  display: block;
  color: $form-label-color;
  font-size: $font-size;
}

.x-panel-collapsed .x-window-tl {
  border-bottom: 1px solid $panel-border-color;
}

.x-window-maximized .x-window-handle {
  display: none;
}

.x-window-sizing-ghost ul {
  border: 0 !important;
}

.x-dlg-focus {
  outline: 0 none;
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.ext-webkit .x-dlg-focus {
  width: 1px;
  height: 1px;
}

.x-dlg-mask {
  z-index: 10000;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
}

.x-window-plain {
  .x-window-mc {
    border: 1px solid;
  }

  .x-window-body {
    border: 1px solid;
    background: transparent !important;
  }
}

.x-window-dlg,
.x-window.active-window {
  @include material-shadow();
}

.x-window {
  border: 1px $window-border-color solid;
  background-color: $window-background-color;

  @if $window-border-radius != 0 {
    border-radius: $window-border-radius;
  }

  .x-window-handle {
    opacity: 0;
  }

  .x-window-header,
  .x-window-tl,
  .x-window-tr {
    color: $window-header-font-color;
    font-weight: bold;
    background-color: $window-header-background-color;

    @if $window-header-background != $window-header-background-color {
      background: $window-header-background;
    }
  }

  .x-window-header,
  .x-window-tr {
    @if $window-border-radius != 0 {
      border-top-right-radius: $window-border-radius;
    }
  }

  .x-window-tl {
    border-bottom: 1px $window-border-color solid;

    @if $window-border-radius != 0 {
      border-top-left-radius: $window-border-radius;
      border-top-right-radius: $window-border-radius;
    }
  }
}

.x-window-body {
  background: $window-body-background-color;
}

.x-window-dirty .x-window-header-text {
  font-style: italic;

  &::after {
    color: $text-color;
    content: ' *';
  }
}

.x-window-noborder .x-window-mc,
.x-window-plain .x-window-body-noborder {
  border-width: 0;
}
