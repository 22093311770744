.x-slider-inner {
  position: relative;
  left: 0;
  top: 0;
  overflow: visible;
}

.x-slider-focus {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  line-height: 1px;
  font-size: 1px;
  outline: 0 none;
  -moz-user-select: none;
  -webkit-user-select: ignore;
  display: block;
  overflow: hidden;
}

.x-slider-horz {
  padding-left: 7px;
  background: url('~slider/slider-bg.png') transparent no-repeat 0 -22px;

  .x-slider-end,
  .x-slider-inner {
    background-image: url('~slider/slider-bg.png');
  }

  .x-slider-end {
    padding-right: 7px;
    background: transparent no-repeat right -44px;
  }

  .x-slider-inner {
    background: transparent repeat-x 0 0;
    height: 22px;
  }

  .x-slider-thumb {
    width: 14px;
    height: 15px;
    position: absolute;
    left: 0;
    top: 3px;
    background: url('~slider/slider-thumb.png') transparent no-repeat 0 0;
  }

  .x-slider-thumb-over {
    background-position: -14px -15px;
  }

  .x-slider-thumb-drag {
    background-position: -28px -30px;
  }
}

.x-slider-vert {
  padding-top: 7px;
  background: url('~slider/slider-v-bg.png') transparent no-repeat -44px 0;
  width: 22px;

  .x-slider-end,
  .x-slider-inner {
    background-image: url('~slider/slider-v-bg.png');
  }

  .x-slider-end {
    padding-bottom: 7px;
    background: transparent no-repeat -22px bottom;
  }

  .x-slider-inner {
    background: transparent repeat-y 0 0;
  }

  .x-slider-thumb {
    width: 15px;
    height: 14px;
    position: absolute;
    left: 3px;
    bottom: 0;
    background: url('~slider/slider-v-thumb.png') transparent no-repeat 0 0;
  }

  .x-slider-thumb-over {
    background-position: -15px -14px;
  }

  .x-slider-thumb-drag {
    background-position: -30px -28px;
  }
}
