@mixin secondary-button($toolbar: false) {
  &:not(.x-item-disabled) {
    color: $button-font-color;

    button {
      color: $button-font-color;
    }

    @if not $toolbar {
      background: $button-background;
    }

    // secondary's active state without ripple

    @if not $button-ripple {
      &:active,
      &.x-btn-click,
      &.x-btn-menu-active,
      &.x-btn-pressed {
        background-color: $button-active-background-color;

        @if not $button-flat {
          border: $button-active-border;
        }

        .x-btn-text {
          color: $button-active-font-color;
        }
      }
    }

    &:hover,
    &.x-btn-over {
      @if not $button-flat {
        border: $button-hover-border;
      }

      @if not $toolbar {
        @if $button-hover-background != $button-hover-background-color {
          background: $button-hover-background;
        }

        background-color: $button-hover-background-color;
      }

      &,
      button {
        color: $button-hover-font-color;
      }
    }
  }

  &.x-item-disabled {
    @if not $toolbar {
      background: $button-disabled-background;
    }

    button {
      color: $button-disabled-font-color;
    }

    @if not $button-flat {
      border: $button-disabled-border;
    }
  }
}
