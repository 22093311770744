body,
html {
  margin: 0;
  padding: 0;
}

body {
  font-family: $font-family;
  font-size: $font-size;
}

address,
blockquote,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
label,
li,
ol,
p,
pre,
td,
th,
ul {
  margin: 0;
  padding: 0;
}

img {
  border: 0;
}

q {
  &::after,
  &::before {
    content: '';
  }
}

.ext-forced-border-box {
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  * {
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  }
}
