.toolbar {
  .mail-state,
  .checkbox {
    display: inline-block;
    margin-right: 10px;
  }

  .checkbox {
    margin: 5px 10px;
  }

  .pagination {
    display: inline-block;
    vertical-align: middle;
  }
}
