#next-desktop-center {
  > div.x-panel-bwrap {
    @if $background-image != '' {
      background: transparent $background-image 50% 50% no-repeat;
    }

    > div.x-panel-body {
      background-color: transparent;

      @if $powered-by == true {
        background-image: url('~powered-by.png');
        background-position: 97% 97%;
        background-repeat: no-repeat;
        background-size: 170px;
      }
    }
  }
}

.logo-panel {
  @if $login-image != '' {
    background: transparent $login-image center no-repeat;
  } @else if $background-image != '' {
    background: transparent $background-image center no-repeat;
  }
}
