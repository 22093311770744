.x-splitbar-proxy {
  background-color: $footer-background-color;
  position: absolute;
  visibility: hidden;
  z-index: 20001;
  line-height: 1px;
  font-size: 1px;
  overflow: hidden;
}

.x-splitbar-h,
.x-splitbar-proxy-h {
  cursor: e-resize;
  cursor: col-resize;
}

.x-splitbar-proxy-v,
.x-splitbar-v {
  cursor: s-resize;
  cursor: row-resize;
}
