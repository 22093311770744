.Bs fieldset,
fieldset {
  border: 0;
  border-top: 1px solid $form-border-color;
  margin-top: 14px;
  margin-bottom: 10px;
  display: block;
  overflow: hidden;

  legend {
    padding-left: 0;
    font-weight: bold;
    font-size: $font-size-large;
    color: $legend-color;

    /* line-height to match toggle size */
    line-height: 14px;
    margin-bottom: 8px;
    padding-right: 4px;

    .x-tool-toggle {
      margin-right: 3px;
      margin-left: 0;
      float: left !important;
    }

    input {
      margin-right: 3px;
      float: left !important;
      height: 13px;
      width: 13px;
    }
  }

  &.x-panel-collapsed {
    padding-bottom: 0 !important;
    border-width: 1px 1px 0 !important;
    border-left-color: transparent;
    border-right-color: transparent;

    .x-tool-toggle {
      @include icon('plus');
    }

    .x-fieldset-bwrap {
      visibility: hidden;
      position: absolute;
      left: -1000px;
      top: -1000px;
    }
  }

  .x-tool-toggle {
    @include icon('minus');
    @include icon-size(10px);

    width: 12px;
    height: 12px;
    line-height: 12px !important;

    &::before {
      margin-left: 1px;
    }
  }
}

.ux-itemselect fieldset {
  margin-top: 0;
  margin-bottom: 0;
}

/* make top of checkbox/tools visible in webkit */
.ext-webkit .x-fieldset-header {
  padding-top: 1px;
  padding-bottom: 1px;
}

.x-fieldset-noborder {
  border: 0 transparent;

  legend {
    margin-left: -3px;
  }
}

.x-fieldset-bwrap,
.x-fieldset-body {
  overflow: hidden;
}

.customer fieldset.x-fieldset {
  margin-right: 5px;
}

.x-fieldset .fieldset-link a {
  line-height: $form-element-height;
  color: $link-color;
  padding: 0 5px;
}
