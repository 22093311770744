.mail-searchbox {
  display: table;
  padding: 0 0 5px;

  .mail-searchbox-input-container,
  .mail-searchbox-controls-container {
    display: table-cell;

    .form-group {
      border-style: solid;
      border-color: $form-border-color;
      border-width: 1px 0 1px 1px;
      margin: 0;
      padding: 0 5px;
      height: $button-text-height;
      line-height: $button-text-height - 2;

      label.radio-inline {
        margin-left: 2px;
        margin-right: 5px;
        font-size: $button-font-size;
        color: $text-color;

        input {
          margin-top: 5px;
          margin-left: -17px;
        }
      }
    }

    .Select-control {
      border-width: 1px 0 1px 1px;
    }
  }

  .mail-searchbox-input-container {
    float: left;
    width: 100%;

    .autocomplete-container > input {
      border-width: 1px;
    }
  }

  .mail-searchbox-controls-container {
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;

    button {
      border-radius: 0;
    }
  }

  .input-daterange {
    width: 220px;

    input {
      height: $button-text-height;
    }
  }
}
