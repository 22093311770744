// common patterns we use e.g. in shared service providers, quarantine, mailboxes etc
// they all have toolbars with buttons on both sides

.toolbar {
  margin: 10px 0;

  .btn-toolbar > .btn,
  .indicator {
    margin-bottom: 5px;
  }

  .indicator {
    margin-right: 10px;
    margin-left: 10px;
  }

  .indicator,
  .right-button-toolbar {
    display: inline-block;
    vertical-align: middle;
  }
}
