$form-text-background-color: #fff !default;
$form-text-border-color: $tab-border-color !default;
$form-text-focus-background-color: #fff !default;
$form-text-focus-border-color: $button-border-color !default;

$form-text-invalid-background-color: #fee !default;
$form-text-invalid-border-color: $warning-border-color !default;
$form-invalid-background-image: url('~grid/invalid_line.gif') !default;

$form-label-color: #555 !default;
$form-help-font-size: 11px !default;
$form-item-font-size: $font-size !default;
$form-text-input-height: 18px !default;
$form-border-color: $form-text-border-color !default;
$form-input-text-color: #000 !default;
$form-input-read-only-background-color: $button-disabled-background;
$form-element-height: 22px;
$form-input-disabled-opacity: 0.6 !default;
$form-input-readonly-opacity: 0.75 !default;

$legend-color: #777 !default;

$form-input-line-height: 16px !default;
