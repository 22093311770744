.n-group-panel-header {
  ul.x-tab-strip-top {
    padding-top: 3px;
    background-color: $tab-large-strip-background-color !important;

    @if $tab-large-strip-background != $tab-large-strip-background-color {
      background: $tab-large-strip-background;
    }
  }

  ul.x-tab-strip li {
    @include icon-font();
    @include icon-size($size: 32px, $valign: baseline);
    @include icon-color($tab-large-icon-color);

    padding-top: 10px;
    margin-bottom: 3px;
    text-align: center;

    @if $button-flat {
      border: 0;
    } @else {
      border-radius: $tab-large-border-radius;
    }

    background: $tab-large-background;

    span.x-tab-strip-text {
      color: $tab-large-font-color;
      font-weight: $tab-large-font-weight;
    }

    &.x-tab-strip-over,
    &:hover {
      @include icon-color($tab-large-hover-icon-color);

      @if not $button-flat {
        border: $tab-large-hover-border;
      }

      @if $tab-large-hover-background != $tab-large-hover-background-color {
        background: $tab-large-hover-background;
      }

      background-color: $tab-large-hover-background-color;
      cursor: pointer;

      span.x-tab-strip-text {
        color: $tab-large-hover-font-color;
        font-weight: $tab-large-hover-font-weight;
      }
    }

    &.x-tab-strip-active {
      @include icon-color($tab-large-active-icon-color);

      @if not $button-flat {
        border: $tab-large-active-border;
      }

      background: $tab-large-active-background;

      span.x-tab-strip-text {
        color: $tab-large-active-font-color;
        font-weight: $tab-large-active-font-weight;
      }
    }

    &.vendor-tab {
      @include icon('vendor');
    }

    &.distributor-tab {
      @include icon('distributor');
    }

    &.reseller-tab {
      @include icon('reseller');
    }

    &.customer-tab {
      @include icon('customer');
    }

    &.inbound-scrubbing-tab {
      @include icon('smx-inbound-mail');
    }

    &.inbound-scrubbing-tab.expired-group-tab {
      @include icon('smx-inbound-mail');
    }

    &.outbound-scrubbing-tab {
      @include icon('smx-outbound-mail');
    }

    &.outbound-scrubbing-tab.expired-group-tab {
      @include icon('smx-outbound-mail');
    }

    &.mail-hosting-tab {
      @include icon('smx-mail-hosting');
    }

    &.mail-hosting-tab.expired-group-tab {
      @include icon('smx-mail-hosting');
    }

    &.vendor-tools-tab {
      @include icon('gear');
    }

    &.SMX-VendorMail {
      @include icon('smx-envelope');
    }

    &.SMX-Agreement,
    &.SMX-Product,
    &.SMX-ResellerAgreement {
      @include icon('product');
    }

    &.SMX-InvoiceRun {
      @include icon('invoice-run');
    }

    &.SMX-Invoice {
      @include icon('document');
    }

    &.user-tab {
      @include icon('user');
    }

    &.smartrules-tab {
      @include icon('smx-smartrules');
    }

    &.smartrules-tab.expired-group-tab {
      @include icon('smx-smartrules');
    }

    &.archiving-tab {
      @include icon('smx-archiving');

      &::before {
        font-size: 24px !important;
        line-height: 35px;
      }
    }

    &.archiving-tab.expired-group-tab {
      @include icon('smx-archiving');
    }

    &.continuity-tab,
    &.quarantine-tab {
      svg {
        fill: $tab-large-icon-color;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        padding: 2px 0 3px;
      }
    }

    &.shared-services-tab,
    &.shared-services-tab.expired-group-tab {
      @include icon('smx-shared-services');
    }

    &.expired-group-tab {
      @include icon-color($tab-large-band-expired-icon-color, $where: 'after');
      @include icon-font($where: 'after');
      @include icon('expired-band', $where: 'after');

      position: relative;

      &::after {
        display: block;
        position: absolute;
        top: 0;
        font-size: 32px;
        line-height: 32px;
      }
    }
  }
}
