$title: 'SMX3';

$powered-by: false;
$use-gradients: false; // material ui doesn't use gradients

// TODO pick newer smx colors from https://confluence.smxemail.com/display/MKTG/Brand+and+Logos?preview=/22839725/62882118/SMX%20Brand%20Guidelines%20April%202020.pdf

// old primary and secondary colors

$smx-picton-blue: #549fd7;
$smx-cyan-blue: #2f87c9;
$smx-dark-blue: #15428b;
$smx-shuttle-grey: #546272;
$smx-link-water: #c8dff0;

// shades of grey

$smx-white: #fff;
$smx-offwhite: #ebebeb;
$smx-faint-grey: #d8d8d8;
$smx-light-grey: #a8a8a8;
$smx-grey: #828282;
$smx-dim-grey: #636363;
$smx-dark-grey: #3c3c3c;

// others

$smx-light-yellow: #e6e6c4;

// common

$brand-color: $smx-picton-blue;
$neutral-color: $smx-offwhite;
$text-color: $smx-dark-grey;

// buttons (general)

$button-flat: true;
$button-ripple: true;
$button-border-radius: 2px;
$button-font-weight: 500;

// primary buttons

$button-primary-background-color: $brand-color;
$button-primary-background: $button-primary-background-color;
$button-primary-font-color: $smx-white;

$button-primary-hover-background-color: $smx-cyan-blue;

$button-primary-disabled-font-color: $smx-grey;
$button-primary-disabled-background-color: $smx-faint-grey;

// secondary buttons

$button-background-color: $neutral-color;
$button-background: $button-background-color;
$button-border-color: $smx-grey;
$button-font-color: $text-color;

$button-hover-background-color: $smx-faint-grey;
$button-hover-background: $button-hover-background-color;
$button-hover-font-color: $button-font-color;

$button-disabled-background: $button-primary-disabled-background-color;

// header

$header-background-color: $brand-color;
$header-background: $header-background-color;

$header-font-color: $smx-white;

$header-button-font-color: $header-font-color;
$header-button-border-color: rgba($header-button-font-color, 0.32);
$header-button-background-color: transparent;
$header-button-background: transparent;

$header-button-hover-background-color: $smx-cyan-blue;
$header-button-hover-background: $header-button-hover-background-color;
$header-button-hover-border-color: $header-button-hover-background-color;
$header-button-hover-font-color: $smx-white;
$header-button-hover-icon-color: $header-button-hover-font-color;

// tabs

$tab-border-radius: 0;
$tab-border-color: $smx-faint-grey;

$tab-font-color: $smx-dim-grey;
$tab-active-font-color: $tab-font-color;
$tab-hover-font-color: $text-color;

$tab-large-strip-background-color: $smx-light-grey;
$tab-large-strip-background: $tab-large-strip-background-color;

$tab-large-background-color: $brand-color;
$tab-large-background: $tab-large-background-color;
$tab-large-hover-background-color: $header-button-hover-background-color;
$tab-large-font-color: $smx-white;

$tab-large-hover-font-color: $tab-large-font-color;

$tab-large-active-background-color: $smx-shuttle-grey;
$tab-large-active-font-color: $smx-white;

$tab-large-band-expired-icon-color: $smx-light-yellow;

// toolbar

$toolbar-button-background: $smx-white;
$toolbar-button-border-color: transparent;
$toolbar-button-hover-background: $smx-faint-grey;

// desktop

$desktop-background: $neutral-color;
$desktop-border-bottom-width: 0;

// logo

$logo-width: 140px;
$logo-left-margin: 10px;
$top-bar-height: 75px;
$portal-logo-font-size: 90px;

// footer

$footer-background-color: $smx-light-grey;
$footer-button-active-background-color: $tab-large-active-background-color;
$footer-button-active-background: $footer-button-active-background-color;

// window

$window-border-radius: 0;
$window-flat: true;

// form

$form-label-color: $smx-dim-grey;
$legend-color: $smx-dim-grey;
$form-input-disabled-opacity: 0.57;
$combo-list-background-color: $smx-faint-grey;

// panel

$panel-flat: true;
$panel-primary-border-color: $smx-light-grey;
$panel-header-text-color: $smx-dark-blue;
$panel-header-font-color: $text-color;

// grid

$grid-header-border-left-color: transparent;
$grid-item-icon-color: $button-primary-background-color;
$grid-resize-background-color: $smx-dim-grey;
$grid-border-color: $neutral-color;
$grid-row-over-background-color: $smx-offwhite;
$grid-selected-row-background-color: $smx-link-water;

// menu

$menu-active-background-color: $smx-faint-grey;
$menu-active-font-color: $text-color;
$menu-active-border-color: $menu-active-background-color;

// unsorted

$smartrules-panel-button-hover-icon-color: $button-font-color;
$icon-explorer-color: $smx-grey;
$modal-tool-button-icon-color: $smx-dim-grey;
$ghost-panel-background-color: $smx-dim-grey;
$smartrules-icon-color: $modal-tool-button-icon-color;
$modal-tool-button-background: transparent;
$login-background: $smx-faint-grey;
$link-color: $tab-large-background-color;
$qtip-bg-color: $smx-dim-grey;

$icon-help-color: $brand-color;
$information-color: $link-color;

@import '../includes/all';
@import '../includes/legacy/branding';

.Bs .btn,
:not(.x-item-disabled) > .x-btn:not(.x-item-disabled):not(.x-box-item):not(:hover),
.n-group-panel-header ul.x-tab-strip li {
  // taken from material-ui
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

#next-desktop-footer {
  border: 0 !important;

  .x-btn-small .x-btn-text {
    height: $button-text-height;
  }
}

#portal-logo,
#next-desktop-header,
#next-desktop-header table.x-toolbar-ct,
#next-desktop-launcher-wrap {
  height: $top-bar-height;
}

#next-desktop-header .x-toolbar,
#next-desktop-launcher-inner,
#next-desktop-launcher-right {
  padding: 0 !important;
}

#next-desktop-header .xtb-sep {
  display: none !important;
}

#next-desktop-launcher {
  top: 10px;

  .x-toolbar-left > table {
    float: right;
  }

  .x-btn {
    margin-right: 10px !important;
  }
}

#portal-logo {
  width: $logo-width;
  margin-left: $logo-left-margin;
  background: url('~brands/smxemail/smxemail-logo-white-horiz.png') left center no-repeat;
  background-size: contain;
}

#login-form,
#retry-panel {
  @include material-shadow();

  left: 0 !important;
  right: 0 !important;
  top: 20% !important;
  background-color: $smx-white !important;
  padding: 0 0 40px !important;
  width: 390px !important;
  margin: 0 auto 50px;

  .information,
  .warning {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .logo-panel {
    background-image: url('~brands/smxemail/smxemail-logo-color-horiz.png');
    background-position: center 35px;
    background-repeat: no-repeat;
    background-size: 305px;
  }

  form {
    padding: 0 !important;
    width: 390px !important;

    /* Logo */
    > .x-panel {
      margin-bottom: 32px;
      height: 140px;

      /* Form body */
      + .x-panel {
        margin: 0 0 16px !important;
        background-color: $smx-white !important;
        height: auto !important;
        padding: 0 !important;
      }
    }
  }
}

.smartrules-conditions,
.smartrules-actions {
  .x-panel-bwrap > .x-panel-body {
    border-color: transparent;
  }
}
