$dialog-icon-color: $text-color !default;
$dialog-wait-icon-color: adjust-color(
  $dialog-icon-color,
  $red: 40,
  $green: 40,
  $blue: 140
) !default;
$dialog-info-icon-color: $dialog-wait-icon-color !default;
$dialog-warning-icon-color: adjust-color(
  $dialog-icon-color,
  $red: 192,
  $green: 32,
  $blue: 16
) !default;
$dialog-question-icon-color: $dialog-wait-icon-color !default;
$dialog-error-icon-color: adjust-color(
  $dialog-icon-color,
  $red: 255,
  $green: 64,
  $blue: 16
) !default;

$modal-dialog-background-color: rgba(#000, 0.1) !default;

$modal-confirmation-svg-fill-color: $form-label-color !default;
