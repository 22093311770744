textarea {
  resize: none;
}

textarea.x-form-field {
  background: repeat-x 0 0;
  border: 1px solid;
  padding: 2px 3px;

  &.has-line-max-length {
    font-family: monospace !important;
  }

  &.readonly-textarea {
    background-color: $form-input-read-only-background-color;
    opacity: $form-input-readonly-opacity;
  }
}

.textarea-max-line {
  position: absolute;
  top: 1px;
  bottom: 0;
  border-right: 1px rgba(0, 0, 0, 0.15) solid;
  pointer-events: none;
}
