@mixin icon-font($name: $icon-font-family, $where: 'before') {
  &:#{$where} {
    font-family: $name;
  }
}

@mixin icon-font-size($size: $button-icon-font-size, $where: 'before') {
  &:#{$where} {
    font-size: $size;
  }
}

@mixin icon-font-position($valign: middle, $where: 'before') {
  &:#{$where} {
    vertical-align: $valign;
  }
}

@mixin icon-size($size: $button-icon-font-size, $where: 'before', $valign: middle) {
  @include icon-font-size($size, $where);
  @include icon-font-position($valign, $where);
}

@mixin icon-color($color, $where: 'before') {
  &:#{$where} {
    color: $color;
  }
}

@mixin icon($name, $where: 'before') {
  &:#{$where} {
    content: icon-character-for-name($name);
  }
}

@mixin icon-set-before($name, $size) {
  @include icon-font($icon-font-family, 'before');
  @include icon-size($size);

  &::before {
    content: icon-character-for-name($name);
  }
}
