.autocomplete-container {
  position: relative;

  .autocomplete-input,
  .autocomplete-shadow {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    margin: 0;
    width: calc(100% + 1px);
    border: 1px solid $form-border-color;
    background-color: transparent;
  }

  .autocomplete-input,
  .autocomplete-shadow,
  .autocomplete-suggestion {
    height: $button-text-height;
    line-height: $button-text-height;
    padding: 0 5px;
    overflow: hidden;
  }

  .autocomplete-input {
    z-index: 2;

    &:hover {
      border-color: $form-text-focus-border-color;
    }
  }

  .autocomplete-suggestions {
    border: 1px solid $form-border-color;
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
    background-color: $form-text-background-color;
    z-index: 3;
  }

  .autocomplete-suggestion-selected {
    background-color: $neutral-color;
  }
}

.Bs .autocomplete-shadow {
  z-index: 1;
  color: $form-border-color;
}
