$header-font-color: #fff !default;
$title-base-color: $header-font-color !default;
$title-background-color: transparent !default;

$title-font-color: #000 !default;

@if $title-background-color != transparent {
  $title-font-color: opposite-color($title-background-color) !default;
} @else {
  $title-font-color: opposite-color($header-background-color) !default;
}

$header-background-color: $base-color !default;
$header-border-color: darken($base-color, 24) !default;
$header-background: linear-gradient(
  to bottom,
  $header-background-color,
  adjust-color($header-background-color, $hue: -6deg, $lightness: -16%, $saturation: -7%)
) !default;

$header-button-border-color: $button-border-color !default;
$header-button-border: 1px $header-button-border-color solid !default;
$header-button-background-color: lighten($header-background-color, 32) !default;

$header-button-background: linear-gradient(
  to bottom,
  $header-button-background-color,
  adjust-color(
    $header-button-background-color,
    $hue: -6deg,
    $lightness: -16%,
    $saturation: -7%
  )
) !default;
$header-button-font-color: $button-font-color !default;

$header-button-hover-border-color: $button-hover-border-color !default;
$header-button-hover-border: 1px $header-button-hover-border-color solid !default;
$header-button-hover-background-color: lighten($header-background-color, 16) !default;
$header-button-hover-background: linear-gradient(
  to bottom,
  $header-button-hover-background-color,
  adjust-color(
    $header-button-hover-background-color,
    $hue: -6deg,
    $lightness: -16%,
    $saturation: -7%
  )
) !default;
$header-button-hover-font-color: $button-hover-font-color !default;

$header-border-bottom-width: 0 !default;

$title-font-size: 16px !default;
$title-padding: 9px 7px !default;

$panel-header-text-color: #15428b !default;
