.x-grid3 {
  position: relative;
  overflow: hidden;
  background-color: $grid-background-color;

  table {
    table-layout: fixed;
  }

  .grid-row-insert-above {
    border-top-color: $icon-success-color;
    border-top-style: solid;
  }

  .grid-row-insert-below {
    border-bottom-color: $icon-success-color;
    border-bottom-style: solid;
  }

  .deprecated-content .x-grid3-cell {
    color: $form-label-color;
  }

  .x-action-col-cell .reorder-button {
    margin: 0 2px;
    width: $button-icon-font-size;
    height: $button-icon-font-size;
    display: inline-block;

    .navigate_up {
      background: url('~icons/navigate_up.png') no-repeat;
    }

    .navigate_down {
      background: url('~icons/navigate_down.png') no-repeat;
    }

    .navigate_disabled {
      cursor: default;
    }
  }
}

.x-grid-panel {
  .x-panel-body {
    overflow: hidden !important;
  }

  .x-panel-mc .x-panel-body {
    border: 1px $panel-border-color solid;
  }
}

.x-grid3-viewport {
  overflow: hidden;
}

.x-grid3-hd-row,
.x-grid3-row,
.x-grid3-summary-row {
  td {
    outline: none;
    -moz-user-focus: normal;
  }
}

.x-grid3-row,
.x-grid3-summary-row {
  td {
    vertical-align: top;
    padding-left: 1px;
    padding-right: 1px;
    font-size: $font-size;
    line-height: 13px;
    color: $text-color;
    -moz-user-select: none;
    -webkit-user-select: ignore;
  }
}

.x-grid3-cell,
.x-grid3-hd {
  -moz-user-select: none;
  -webkit-user-select: ignore;
}

.x-grid3-hd-row {
  td {
    line-height: 15px;
    vertical-align: middle;
    border-left: 1px $grid-header-border-left-color solid;
    border-right: 1px $grid-header-border-right-color solid;
    font-size: $font-size;
  }

  .x-grid3-marker-hd {
    padding: 3px;
  }
}

.x-grid3-cell-inner,
.x-grid3-hd-inner {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 3px 3px 3px 5px;
  white-space: nowrap;
  color: $text-color;
}

/* ActionColumn, reduce padding to accommodate 16x16 icons in normal row height */
.x-action-col-cell .x-grid3-cell-inner {
  padding-top: 1px;
  padding-bottom: 1px;
}

.x-action-col-icon {
  cursor: pointer;
}

.x-grid3-hd-inner {
  position: relative;
  cursor: inherit;
  padding: 4px 3px 4px 5px;
}

.x-grid3-row-body {
  white-space: normal;
}

.x-grid3-body-cell {
  outline: 0 none;
}

.x-grid-row-loading {
  background: $mask-background-color no-repeat center center;
}

.x-grid-page {
  overflow: hidden;
}

.x-grid3-row {
  cursor: default;
  border: 1px $grid-border-color solid;
  width: 100%;
  border-top-color: $grid-border-top-color;

  .x-grid3-marker {
    padding: 3px;
  }
}

.x-grid3-row-over {
  @if not $panel-flat {
    border: 1px $tab-border-color solid;
  }

  @if $panel-flat {
    border-top-color: transparent;
  }

  background: repeat-x left top;
  background-color: $grid-row-over-background-color;
}

.x-grid3-resize-proxy {
  width: 1px;
  left: 0;
  cursor: e-resize;
  cursor: col-resize;
  position: absolute;
  top: 0;
  height: 100px;
  overflow: hidden;
  visibility: hidden;
  border: 0;
  z-index: 7;
}

.x-grid3-resize-marker {
  width: 1px;
  left: 0;
  position: absolute;
  top: 0;
  height: 100px;
  overflow: hidden;
  visibility: hidden;
  border: 0;
  z-index: 7;
}

.x-grid3-focus {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  line-height: 1px;
  font-size: 1px;
  outline: 0 none;
  -moz-user-select: text;
  -webkit-user-select: ignore;
}

.ext-strict .x-grid3-header-pop-inner {
  width: 14px;
}

.x-grid3-header-inner {
  overflow: hidden;
  float: left;
}

.x-grid3-header-offset {
  padding-left: 1px;
  text-align: left;
}

td {
  &.sort-asc,
  &.sort-desc,
  &.x-grid3-hd-menu-open,
  &.x-grid3-hd-over {
    border-left: 1px $grid-header-active-border-color solid;
    border-right: 1px $grid-header-active-border-color solid;
  }

  &.sort-asc .x-grid3-hd-inner,
  &.sort-desc .x-grid3-hd-inner,
  &.x-grid3-hd-menu-open .x-grid3-hd-inner,
  &.x-grid3-hd-over .x-grid3-hd-inner {
    background-image: url('~grid/grid3-hrow-over2.gif');
    background-color: $grid-header-background-color;
    background-repeat: repeat-x;
    background-position: left bottom;
  }
}

.x-grid3-sort-icon {
  background-repeat: no-repeat;
  display: none;
  height: 4px;
  width: 13px;
  margin-left: 3px;
  vertical-align: middle;
}

.sort-asc .x-grid3-sort-icon,
.sort-desc .x-grid3-sort-icon {
  display: inline;
}

.x-grid3-scroller {
  overflow: auto;
  position: relative;
}

.x-grid3-cell-text,
.x-grid3-hd-text {
  display: block;
  padding: 3px 5px;
  -moz-user-select: none;
  -webkit-user-select: ignore;
  color: $text-color;
}

.col-move-bottom,
.col-move-top {
  width: 9px;
  height: 9px;
  position: absolute;
  top: 0;
  line-height: 1px;
  font-size: 1px;
  overflow: hidden;
  visibility: hidden;
  z-index: 20000;
  background: transparent no-repeat left top;
}

.x-grid3-row-selected {
  @if not $panel-flat {
    border: 1px $grid-selected-row-background-color dotted;
  }

  @if $panel-flat {
    border-top-color: transparent;
  }

  background-color: $grid-selected-row-background-color !important;
  background-image: none;
}

.x-grid3-locked {
  .x-grid3-row-selected td.x-grid3-row-marker,
  td.x-grid3-row-marker {
    color: $text-color;
    background: url('~grid/grid-hrow.gif') #ebeadb repeat-x 0 bottom !important;
    vertical-align: middle !important;
    padding: 0;
    border-top: 1px #fff solid;
    border-bottom: 0 !important;
    border-right: 1px #6fa0df solid !important;
    text-align: center;
    // TODO this selector needed?
    border: 10px #2ecc40 dotted;
  }

  .x-grid3-row-selected td.x-grid3-row-marker div,
  td.x-grid3-row-marker div {
    color: $text-color !important;
    padding: 0 4px;
    text-align: center;
  }
}

.x-grid3-dirty-cell {
  background: url('~grid/dirty.gif') transparent no-repeat 0 0;
}

.x-grid3-bottombar,
.x-grid3-topbar {
  overflow: hidden;
  display: none;
  position: relative;
  font-size: $font-size;
}

.x-grid3-topbar .x-toolbar {
  border-right: 0;
}

.x-grid3-col-dd {
  border: 0;
  padding: 0;
  background-color: transparent;
}

.x-dd-drag-ghost .x-grid3-dd-wrap {
  padding: 1px 3px 3px 1px;
}

.x-grid3-hd-menu-open .x-grid3-hd-btn,
.x-grid3-hd-over .x-grid3-hd-btn {
  display: block;
}

a.x-grid3-hd-btn:hover {
  background-position: -14px center;
}

.x-grid3-row-collapsed .x-grid3-row-expander {
  background-position: 4px 2px;
}

.x-grid3-row-expanded .x-grid3-row-expander {
  background-position: -21px 2px;
}

.x-grid3-row-collapsed .x-grid3-row-body {
  display: none !important;
}

.x-grid3-row-expanded .x-grid3-row-body {
  display: block !important;
}

.x-grid3-hd-checker,
.x-grid3-row-checker {
  width: 100%;
  height: 18px;
  background: url('~grid/row-check-sprite.gif') transparent no-repeat 2px 2px;
}

.x-grid3-hd-checker-on .x-grid3-hd-checker,
.x-grid3-row-checked .x-grid3-row-checker,
.x-grid3-row-selected .x-grid3-row-checker {
  background-position: -23px 2px;
}

.x-grid3-hd-checker {
  background-position: 2px 1px;
}

.ext-border-box .x-grid3-hd-checker {
  background-position: 2px 3px;
}

.x-grid3-hd-checker-on .x-grid3-hd-checker {
  background-position: -23px 1px;
}

.ext-border-box .x-grid3-hd-checker-on .x-grid3-hd-checker {
  background-position: -23px 3px;
}

.x-grid3-body {
  .x-grid3-td-numberer {
    color: $button-font-color;
    background: url('~grid/grid3-special-col-bg.gif') transparent repeat-y right;

    .x-grid3-cell-inner {
      padding: 3px 5px 0 0 !important;
      text-align: right;
    }
  }

  .x-grid3-td-row-icon {
    background: url('~grid/grid3-special-col-bg.gif') transparent repeat-y right;
    vertical-align: top;
    text-align: center;

    .x-grid3-cell-inner {
      padding: 0 !important;
      background-position: center center;
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      margin-left: 2px;
      margin-top: 3px;
    }
  }

  .x-grid3-row-selected {
    .x-grid3-td-checker,
    .x-grid3-td-expander,
    .x-grid3-td-numberer {
      background: url('~grid/grid3-special-col-sel-bg.gif') transparent repeat-y right;
    }
  }

  .x-grid3-check-col-td .x-grid3-cell-inner {
    padding: 1px 0 0 !important;
  }

  .x-grid3-td-expander,
  .x-grid3-td-checker {
    background: url('~grid/grid3-special-col-bg.gif') transparent repeat-y right;

    .x-grid3-cell-inner {
      padding: 0 !important;
      height: 100%;
    }
  }
}

.x-grid3-check-col,
.x-grid3-check-col-on {
  width: 100%;
  height: 16px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: transparent;
}

.x-grid-group-hd {
  border-bottom: 2px $panel-border-color solid;
  cursor: pointer;
  padding-top: 6px;

  div.x-grid-group-title {
    background: url('~icons/arrow-opened.png') transparent no-repeat 3px 3px;
    padding: 4px 4px 4px 19px;
    color: $modal-tool-button-icon-color;
    font-size: $font-size;
    font-weight: bold;
  }
}

.x-grid-group-collapsed .x-grid-group-body,
.x-grid-hide-gridsummary .x-grid3-gridsummary-row-inner {
  display: none;
}

.x-grid-empty {
  padding: 10px;
  color: $button-primary-disabled-font-color;
  font-size: $font-size;
}

.x-grid3-row-alt {
  background-color: $desktop-background;
}

.x-grid3-resize-marker,
.x-grid3-resize-proxy {
  background-color: $grid-resize-background-color;
}

.x-grid3-header {
  background: url('~grid/grid3-hrow2.gif') $grid-header-background-color repeat-x 0 bottom;
  cursor: default;
  padding: 1px 0 0;

  .x-grid3-td-checker .x-grid3-hd-inner {
    padding: 0 !important;
    height: 100%;
  }
}

.x-grid3-header-pop {
  border-left: 1px $panel-border-color solid;
  float: right;
  clear: none;
}

.x-grid3-header-pop-inner {
  border-left: 1px $grid-border-color solid;
  width: 14px;
  height: 19px;
  background: url('~grid/hd-pop.gif') transparent no-repeat center center;
}

.sort-asc .x-grid3-sort-icon {
  background-image: url('~grid/sort_asc.gif');
}

.sort-desc .x-grid3-sort-icon {
  background-image: url('~grid/sort_desc.gif');
}

.x-grid3-split {
  background: url('~grid/grid-split.gif') center no-repeat;
  cursor: e-resize;
  cursor: col-resize;
  display: block;
  font-size: 1px;
  height: 16px;
  overflow: hidden;
  position: absolute;
  top: 2px;
  width: 6px;
  z-index: 3;
}

.x-dd-drag-proxy .x-grid3-hd-inner {
  background-image: url('~grid/grid3-hrow-over2.gif');
  background-color: $grid-header-background-color;
  background-repeat: repeat-x;
  background-position: left bottom;
  width: 120px;
  padding: 3px;
  border: 1px $tab-large-active-border-color solid;
  overflow: hidden;
}

.col-move-top {
  background-image: url('~grid/col-move-top.gif');
}

.col-move-bottom {
  background-image: url('~grid/col-move-bottom.gif');
}

.x-grid3-cell-selected {
  background-color: $button-hover-background-color !important;
  color: $text-color;

  span {
    color: $text-color !important;
  }

  .x-grid3-cell-text {
    color: $text-color;
  }
}

.x-grid3-bottombar .x-toolbar {
  border-right: 0;
  border-bottom: 0;
  border-top: 1px $panel-primary-border-color solid;
}

.x-props-grid {
  .x-grid3-cell {
    padding: 1px;
  }

  .x-grid3-td-name .x-grid3-cell-inner {
    color: $text-color !important;
    background: url('~grid/grid3-special-col-bg.gif') transparent repeat-y -16px !important;
    padding-left: 12px;
  }

  .x-grid3-body .x-grid3-td-name {
    background-color: #fff !important;
    padding: 1px 0 1px 1px;
    border: 0;
    border-right: 1px $grid-border-color solid;
  }
}

.xg-hmenu-sort-asc .x-menu-item-icon {
  @include icon-size(16px);
  @include icon-font();
  @include icon('arrow-up');
}

.xg-hmenu-sort-desc .x-menu-item-icon {
  @include icon-size(16px);
  @include icon-font();
  @include icon('arrow-down');
}

.xg-hmenu-lock .x-menu-item-icon {
  background-image: url('~grid/hmenu-lock.gif');
}

.xg-hmenu-unlock .x-menu-item-icon {
  background-image: url('~grid/hmenu-unlock.gif');
}

.x-grid3-hd-btn {
  display: none;
  position: absolute;
  width: 14px;
  background-image: url('~grid/grid3-hd-btn.gif');
  background-color: $grid-header-background-color;
  background-repeat: no-repeat;
  background-position: left center;
  right: 0;
  top: 0;
  z-index: 2;
  cursor: pointer;
}

.x-grid3-row-expander {
  background: url('~grid/row-expand-sprite.gif') transparent no-repeat 4px 2px;
  width: 100%;
  height: 18px;
}

.x-grid3-check-col {
  background: url('~icons/checkbox-unchecked.png') no-repeat;
}

.x-grid3-check-col-on {
  background: url('~icons/checkbox-checked.png') no-repeat;
}

.x-grid-group-collapsed .x-grid-group-hd div.x-grid-group-title {
  background-image: url('~icons/arrow-closed.png');
}

.x-group-by-icon {
  background-image: url('~grid/group-by.gif');
}

.x-cols-icon {
  background-image: url('~grid/columns.gif');
}

.x-show-groups-icon {
  background-image: url('~grid/group-by.gif');
}

.x-grid-with-col-lines {
  .x-grid3-row {
    border-top-color: $grid-border-color;

    td.x-grid3-cell {
      padding-right: 0;
      border-right: 1px $grid-border-color solid;
    }
  }

  .x-grid3-row-selected {
    border-top-color: darken($grid-border-color, 15%);
  }
}

.x-grid3-gridsummary-row-inner {
  overflow: hidden;
  width: 100%;
  text-align: left;
  background-color: $grid-summary-background-color;
}

.x-grid3-gridsummary-row-offset {
  width: 10000px;
}

.smx-grid-reorderable {
  .x-grid3-td-reorderable {
    vertical-align: middle;
  }

  .x-grid3-col-reorderable {
    padding: 0;
    cursor: move;
  }

  .x-grid3-row-over .x-grid3-col-reorderable {
    @include icon-font();
    @include icon-size(14px);
    @include icon-color($grid-item-icon-color);
    @include icon('drag-position');
  }

  &.smx-grid-dragging {
    .x-grid3-row-over .x-grid3-col-reorderable {
      background: none;
    }
  }
}

.x-pivotgrid {
  .x-grid3-header-offset,
  .x-grid3-row-headers {
    table {
      width: 100%;
      border-collapse: collapse;

      td {
        text-overflow: ellipsis;
        font-size: $font-size;
      }
    }
  }

  .x-grid3-header-offset table {
    td {
      background: url('~grid/grid3-hrow2.gif') repeat-x 50% 100%;
      border-left: 1px $panel-border-color solid;
      border-right: 1px $panel-border-color solid;
      padding: 4px 3px 4px 5px;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      line-height: 13px;
    }
  }

  .x-grid3-row-headers {
    display: block;
    float: left;
    background-color: $grid-header-background-color;

    table {
      height: 100%;

      td {
        background: $neutral-color url('~grid/grid3-rowheader.gif') repeat-x left top;
        border-left: 1px $grid-border-color solid;
        border-right: 1px $panel-border-color solid;
        border-bottom: 1px $panel-border-color solid;
        height: 18px;
        padding: 2px 7px 0 0;
        text-align: right;
      }
    }
  }
}

.x-grid3-header-title {
  top: 0;
  left: 0;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  font-size: $font-size;
  padding-top: 1px;
  padding-bottom: 1px;
  display: table-cell;

  span {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    margin-top: -6px;
  }
}
