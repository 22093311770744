.x-progress-wrap {
  border: 1px $button-border-color solid;
  overflow: hidden;
}

.x-progress-inner {
  height: 18px;
  background: url('~qtip/bg.gif') $desktop-background repeat-x;
  position: relative;
}

.x-progress-bar {
  height: 18px;
  float: left;
  width: 0;
  background: $footer-background-color repeat-x left center;
  border-top: 1px $desktop-background solid;
  border-bottom: 1px $button-border-color solid;
  border-right: 1px $button-border-color solid;
}

.x-progress-text {
  padding: 1px 5px;
  overflow: hidden;
  position: absolute;
  left: 0;
  text-align: center;
  font-size: $font-size;
  font-weight: bold;
  color: $header-font-color;
}

.x-progress-text-back {
  line-height: 16px;
  color: $modal-tool-button-icon-color;
}
