.quarantine-panel .modal {
  .targets-form-group-wrapper {
    margin-top: 15px;

    .membership-list {
      min-height: 150px;
      height: 35vh;
      overflow-y: auto;

      .panel-body {
        ul {
          margin-bottom: 0;

          li:last-child {
            .form-group {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .panel.collapsible:last-child {
      margin-bottom: 0;
    }
  }
}
