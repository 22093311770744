.panel {
  &.disabled {
    opacity: 0.65;

    .panel-body {
      background: lighten($panel-primary-heading-bg, 25);
    }
  }

  .panel-heading {
    color: $button-font-color;
    background: lighten($panel-primary-heading-bg, 15);
  }

  .panel-title {
    font-size: $font-size-large;

    small {
      color: $headings-small-color;
    }
  }

  .panel-body {
    .checkbox label {
      color: $form-input-text-color;
    }
  }

  &.collapsible {
    .panel-heading {
      border-bottom: 0 transparent;

      .panel-title a {
        @include icon-set-before('up', 14px);

        display: block;
        cursor: pointer;

        &::before {
          float: right;
          color: $form-label-color;
        }

        &.collapsed {
          @include icon('down', 'before');
        }

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }

    .panel-body {
      border-top: 1px $panel-primary-border solid;
    }
  }
}
