.x-window-dlg {
  .ext-mb-icon {
    @include icon-font();
    @include icon-size(32px);
    @include icon-color($dialog-icon-color);

    margin-left: 8px;
    float: left;
    width: 47px;
    height: 32px;
  }

  .x-window-header-text {
    font-size: $font-size;
  }

  .ext-mb-textarea {
    font: normal $font-size $font-family;
  }

  .x-msg-box-wait {
    @include icon-color($dialog-wait-icon-color);
    @include icon('spinner');

    background: transparent no-repeat left;
    display: block;
    width: 300px;
    padding-left: 18px;
    line-height: 18px;
  }

  .ext-mb-info {
    @include icon-color($dialog-info-icon-color);
    @include icon('info');
  }

  .ext-mb-warning {
    @include icon-color($dialog-warning-icon-color);
    @include icon('warning');
  }

  .ext-mb-question {
    @include icon-color($dialog-question-icon-color);
    @include icon('help');
  }

  .ext-mb-error {
    @include icon-color($dialog-error-icon-color);
    @include icon('cancel-circle');
  }

  .x-window-body {
    border: 0 !important;
    padding: 5px 10px;
    overflow: hidden !important;
  }

  .x-window-mc {
    border: 0 !important;
  }

  .ext-mb-textarea,
  .x-progress-wrap {
    margin-top: 4px;
  }

  .x-dlg-icon .ext-mb-content {
    zoom: 1;
    margin-left: 47px;
  }

  .ext-mb-error,
  .ext-mb-info,
  .ext-mb-question,
  .ext-mb-warning {
    background: transparent no-repeat top left;
  }
}
