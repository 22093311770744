.x-tree {
  .explorer-SMX-Customer,
  .explorer-SMX-Distributor,
  .explorer-SMX-InvoiceRun,
  .explorer-SMX-Product,
  .explorer-SMX-Report,
  .explorer-SMX-Reseller,
  .explorer-SMX-User {
    @include icon-font();
    @include icon-size($size: 16px);
    @include icon-color($icon-explorer-color);
  }

  .explorer-SMX-Distributor {
    @include icon('distributor');
  }

  .explorer-SMX-Reseller {
    @include icon('reseller');
  }

  .explorer-SMX-Customer {
    @include icon('customer');
  }

  .explorer-SMX-User {
    @include icon('user');
  }

  .explorer-SMX-InvoiceRun {
    @include icon('invoice-run');
  }

  .explorer-SMX-Product {
    @include icon('product');
  }

  .explorer-SMX-Report {
    @include icon('smx-reporting');
  }

  .explorer-SMX-no-icon {
    display: none;
  }
}

.x-tree-ec-icon,
.x-tree-elbow,
.x-tree-elbow-end,
.x-tree-elbow-end-minus,
.x-tree-elbow-end-plus,
.x-tree-elbow-line,
.x-tree-elbow-minus,
.x-tree-elbow-plus,
.x-tree-icon {
  height: $button-icon-font-size;
  vertical-align: top;
  width: $button-icon-font-size;
  background-repeat: no-repeat;
}

.x-tree-node-collapsed,
.x-tree-node-expanded,
.x-tree-node-leaf {
  .x-tree-node-icon {
    // img tag replaced with div by SMX.tree.TreeNodeUI.renderElements.
    // img tag default is inline, div tag default is block.

    display: inline;
    border: 0;
    padding: 0;
    background-position: center;
    background-repeat: no-repeat;
  }
}

input.x-tree-node-cb {
  margin-left: 1px;
  height: 19px;
  vertical-align: bottom;
}

.x-tree-noicon .x-tree-node-icon {
  width: 0;
  height: 0;
}

.x-tree-no-lines {
  .x-tree-elbow,
  .x-tree-elbow-end,
  .x-tree-elbow-line {
    background-color: transparent;
  }

  .x-tree-elbow-end-plus,
  .x-tree-elbow-plus {
    background: url('~icons/arrow-closed.png') no-repeat;
  }

  .x-tree-elbow-end-minus,
  .x-tree-elbow-minus {
    background: url('~icons/arrow-opened.png') no-repeat;
  }
}

.x-tree-arrows {
  .x-tree-elbow,
  .x-tree-elbow-end,
  .x-tree-elbow-line {
    background-color: transparent;
  }

  .x-tree-elbow-plus {
    background: transparent no-repeat 0 0;
  }

  .x-tree-elbow-minus {
    background: transparent no-repeat -16px 0;
  }

  .x-tree-elbow-end-plus {
    background: transparent no-repeat 0 0;
  }

  .x-tree-elbow-end-minus {
    background: transparent no-repeat -16px 0;
  }

  .x-tree-ec-over {
    .x-tree-elbow-plus {
      background-position: -32px 0;
    }

    .x-tree-elbow-minus {
      background-position: -48px 0;
    }

    .x-tree-elbow-end-plus {
      background-position: -32px 0;
    }

    .x-tree-elbow-end-minus {
      background-position: -48px 0;
    }
  }
}

.x-tree-elbow-end-minus,
.x-tree-elbow-end-plus,
.x-tree-elbow-minus,
.x-tree-elbow-plus,
.x-tree-node-el {
  cursor: pointer;
}

.x-dd-drag-ghost a,
.x-tree-node a {
  text-decoration: none;
  -moz-user-select: none;
  -webkit-user-select: ignore;
  -moz-user-focus: normal;
  outline: 0 none;
  vertical-align: middle;

  span {
    text-decoration: none;
    padding: 1px 3px 1px 2px;
  }
}

.x-tree-node {
  white-space: nowrap;
  color: $tree-font-color;

  a {
    color: $text-color;
  }

  .x-tree-node-disabled .x-tree-node-icon {
    opacity: 0.5;
  }

  .x-tree-node-inline-icon {
    background-color: transparent;
    background-image: none;
  }

  a:hover {
    text-decoration: none;
  }

  .x-tree-node-disabled a span {
    color: $button-primary-disabled-font-color !important;
  }

  div {
    &.x-tree-drag-insert-below {
      border-bottom: 1px $header-button-hover-background-color dotted;
    }

    &.x-tree-drag-insert-above {
      border-top: 1px $header-button-hover-background-color dotted;
    }
  }

  .x-tree-drag-append a span {
    background-color: $button-hover-background-color;
    border: 1px $button-border-color dotted;
  }

  .x-tree-node-over {
    background-color: $tree-hover-background-color;
  }

  .x-tree-selected {
    background-color: $tree-selected-background-color;
  }

  .x-tree-node-over a span {
    color: $tree-hover-font-color;
  }

  .x-tree-selected a span {
    color: $tree-selected-font-color;
  }
}

.x-dd-drag-ghost a:hover {
  text-decoration: none;
}

.x-tree-dd-underline .x-tree-node div {
  &.x-tree-drag-insert-below {
    border-bottom: 0;
  }

  &.x-tree-drag-insert-above {
    border-top: 0;
  }

  &.x-tree-drag-insert-below a {
    border-bottom: 2px $header-button-hover-background-color solid;
  }

  &.x-tree-drag-insert-above a {
    border-top: 2px $header-button-hover-background-color solid;
  }
}

.x-dd-drag-ghost {
  .x-tree-ec-icon,
  .x-tree-node-indent {
    display: none !important;
  }
}

.x-tree-node-collapsed .x-tree-node-icon,
.x-tree-node-expanded .x-tree-node-icon {
  @include icon-font();
  @include icon-size();
  @include icon-color($icon-explorer-color);
}

.x-tree-node-expanded .x-tree-node-icon {
  @include icon('folder-open');
}

.x-tree-node-collapsed .x-tree-node-icon {
  @include icon('folder');
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  12.5% {
    transform: rotate(45deg);
  }

  25% {
    transform: rotate(45deg);
  }

  37.5% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(135deg);
  }

  62.5% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(225deg);
  }

  87.5% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(315deg);
  }
}

.x-tree-node-loading .x-tree-node-icon {
  @include icon('loop');

  animation: rotate 2s infinite;
}

.x-tree-node-loading a span {
  font-style: italic;
  color: $text-color;
}

.x-tree-lines {
  .x-tree-elbow {
    background: extImage('elbow');
  }

  .x-tree-elbow-plus {
    background: extImage('elbow-plus');
  }

  .x-tree-elbow-minus {
    background: extImage('elbow-minus');
  }

  .x-tree-elbow-end {
    background: extImage('elbow-end');
  }

  .x-tree-elbow-end-plus {
    background: extImage('elbow-end-plus');
  }

  .x-tree-elbow-end-minus {
    background: extImage('elbow-end-minus');
  }

  .x-tree-elbow-line {
    background: extImage('elbow-line');
  }
}

.x-dd-drag-ghost a,
.x-dd-drag-ghost a span,
.x-tree-node a span {
  color: $tree-font-color;
}

.x-tree-drop-ok-append .x-dd-drop-icon {
  background: extImage('drop-add');
}

.x-tree-drop-ok-above .x-dd-drop-icon {
  background: extImage('drop-over');
}

.x-tree-drop-ok-below .x-dd-drop-icon {
  background: extImage('drop-under');
}

.x-tree-drop-ok-between .x-dd-drop-icon {
  background: extImage('drop-between');
}
