// special treatment for safari because of this bug
// https://bugs.webkit.org/show_bug.cgi?id=160953
// for more see https://youtrack.smxemail.com/issue/SCL-2783
.ext-safari {
  .Bs {
    .modal {
      position: absolute;
      top: 0;
    }

    .modal-dialog {
      margin: 0 auto;
    }
  }

  &.x-body-masked {
    // special treatment for top menu bars in windows
    .x-window {
      .x-window-header,
      .x-tool {
        pointer-events: none;
        background: transparent;
      }
    }
  }
}
