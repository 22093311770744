.event-detail-tab {
  .summary,
  .recipients {
    .x-panel-body {
      padding: 6px 4px;

      .x-form-item {
        .x-form-item-label {
          padding: 2px;
        }

        .x-form-display-field {
          padding: 2px 0;
        }
      }
    }
  }

  .quarantine {
    .x-panel-body {
      border-color: $inline-toolbar-border-color;
    }
  }

  .recipients-toolbar {
    margin-top: 5px;
    border: 1px $inline-toolbar-border-color solid;
  }

  .quarantine .x-toolbar,
  .recipients-toolbar {
    padding: 3px;
    background-color: $inline-toolbar-background-color;

    b {
      font-weight: normal;
      font-size: $font-size-large;
      color: $text-color;
    }
  }

  .recipients {
    .x-panel-body {
      fieldset {
        margin: 3px 0;
        border: 0;
      }
    }
  }
}
