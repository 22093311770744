// inspired by https://www.filamentgroup.com/lab/scrollbars/

$scrollbar-height: 0.55rem !default;
$scrollbar-width: 0.55rem !default;

/* Minimum length of scrollbar thumb (width of horizontal, height of vertical) */
$scrollbar-min-length: 1.5rem !default;

::-webkit-scrollbar {
  height: $scrollbar-height;
  width: $scrollbar-width;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.3rem;
  background-color: rgba(#000, 0.32);

  &:hover {
    background-color: rgba(#000, 0.52);
  }

  &:vertical {
    min-height: $scrollbar-min-length;
  }

  &:horizontal {
    min-width: $scrollbar-min-length;
  }
}
