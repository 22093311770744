.x-color-picker {
  height: 195px;
  position: relative;
  overflow: hidden;

  div canvas {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
}

.x-color-picker-wheel {
  z-index: 1;
}

.x-color-picker-gradient {
  z-index: 0;
}

.x-color-palette {
  width: 150px;
  height: 92px;

  a {
    border: 1px transparent solid;
    float: left;
    padding: 2px;
    text-decoration: none;
    outline: 0 none;
    cursor: pointer;

    &.x-color-palette-sel,
    &:hover {
      background-color: $button-background-color;
      border: 1px darken($button-background-color, 15%) solid;
    }

    em {
      display: block;
      border: 1px $menu-active-border-color solid;

      span {
        cursor: pointer;
        display: block;
        height: 10px;
        line-height: 10px;
        width: 10px;
      }
    }
  }
}
