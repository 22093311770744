@use "sass:math";

@mixin default-button {
  text-align: center;
  white-space: nowrap;

  // excluding those who should remain squared
  &:not(.global-search):not(.x-form-trigger) {
    border-radius: $button-border-radius;
  }

  &:not(.x-form-trigger):not(.x-btn-icon):not(a):not(.prev):not(.next) {
    min-width: $button-min-width;
  }

  @if not $button-flat {
    border: $button-border;
  }

  @if $button-ripple {
    @include button-ripple;
  }

  &.x-date-mp-ok,
  &.x-date-mp-cancel,
  button {
    font-weight: $button-font-weight;
    font-size: $button-font-size;
    text-transform: $button-text-transform;
    cursor: inherit;
    border: 0;
    background-color: transparent;
    padding: 0 $button-padding-x;
    white-space: nowrap;
    height: $button-text-height;
    line-height: $button-text-height;

    @if not $button-flat {
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  &.x-btn-icon,
  &.x-btn-text-icon {
    button {
      @include icon-font();
      @include icon-size(16px);
    }
  }

  &.x-btn-text-icon button::before {
    padding-right: $button-padding-x;
  }

  .x-btn-icon button {
    padding: 0 math.div($button-padding-x, 2);
    width: $button-text-height;
  }

  &.primary {
    @include primary-button;
  }

  &:not(.primary) {
    @include secondary-button;
  }

  &:not(.x-item-disabled) {
    cursor: pointer;
  }

  em {
    font-style: normal;
  }

  .x-btn-arrow {
    display: block;
    background: transparent no-repeat right center;
    padding-right: 6px;
    font-style: normal;
  }
}

.x-btn,
.x-btn-wrap {
  @include default-button;
}

.x-form-field-wrap .x-form-trigger {
  @include icon-font();
}

// adds primary styles to the outermost last button in modal dialogues
.x-window-dlg
  .x-window-footer.x-panel-btns
  .x-toolbar-left-row
  .x-toolbar-cell:not(.x-hide-offsets) {
  &:nth-last-child(1),
  &:nth-last-child(2) {
    .x-btn {
      @include primary-button;
    }
  }
}

.x-row-editor-bwrap .x-btns .x-table-layout-cell {
  &:nth-last-child(1) {
    .x-btn {
      @include primary-button;
    }
  }
}

// extjs crap we cannot remove. so just hide it.
.x-btn-bc,
.x-btn-bl,
.x-btn-br,
.x-btn-ml,
.x-btn-mr,
.x-btn-tc,
.x-btn-tl,
.x-btn-tr {
  display: none !important;
}
