input[type='text'].readonly-checkbox,
input[type='text'].readonly-checkbox-checked {
  display: none;
}

.readonly-checkbox-suffix {
  @include icon-font();
  @include icon-size($size: $font-size);

  border-style: none;
}

.readonly-checkbox + .readonly-checkbox-suffix {
  @include icon('close');
}

.readonly-checkbox-checked + .readonly-checkbox-suffix {
  @include icon('checkmark');
}

div.x-form-item div.x-form-element input {
  &.readonly-checkbox,
  &.readonly-checkbox-checked {
    margin-bottom: 0;
  }
}

.x-editor {
  .x-form-check-wrap {
    padding: 3px;
  }

  .x-form-checkbox {
    height: 13px;
  }
}

/* multi select boxes */
.x-form-check-wrap {
  line-height: 18px;
  height: auto;

  input {
    vertical-align: middle;
  }
}

.x-form-check-group-label {
  border-bottom: 1px solid;
  margin-bottom: 5px;
  padding-left: 3px !important;
  float: none !important;
}
