.x-resizable-handle {
  position: absolute;
  z-index: 100;

  /* ie needs these */
  font-size: 1px;
  line-height: 6px;
  overflow: hidden;
  opacity: 0;
  zoom: 1;
}

.x-resizable-handle-east {
  width: 6px;
  cursor: e-resize;
  right: 0;
  top: 0;
  height: 100%;
}

.x-resizable-handle-south {
  width: 100%;
  cursor: s-resize;
  left: 0;
  bottom: 0;
  height: 6px;
}

.x-resizable-handle-west {
  width: 6px;
  cursor: w-resize;
  left: 0;
  top: 0;
  height: 100%;
}

.x-resizable-handle-north {
  width: 100%;
  cursor: n-resize;
  left: 0;
  top: 0;
  height: 6px;
}

.x-resizable-handle-southeast {
  width: 6px;
  cursor: se-resize;
  right: 0;
  bottom: 0;
  height: 6px;
  z-index: 101;
}

.x-resizable-handle-northwest {
  width: 6px;
  cursor: nw-resize;
  left: 0;
  top: 0;
  height: 6px;
  z-index: 101;
}

.x-resizable-handle-northeast {
  width: 6px;
  cursor: ne-resize;
  right: 0;
  top: 0;
  height: 6px;
  z-index: 101;
}

.x-resizable-handle-southwest {
  width: 6px;
  cursor: sw-resize;
  left: 0;
  bottom: 0;
  height: 6px;
  z-index: 101;
}

.x-resizable-over .x-resizable-handle,
.x-resizable-pinned .x-resizable-handle {
  opacity: 1;
}

.x-resizable-over .x-resizable-handle-east,
.x-resizable-over .x-resizable-handle-west,
.x-resizable-pinned .x-resizable-handle-east,
.x-resizable-pinned .x-resizable-handle-west {
  background-position: left;
}

.x-resizable-over .x-resizable-handle-north,
.x-resizable-over .x-resizable-handle-south,
.x-resizable-pinned .x-resizable-handle-north,
.x-resizable-pinned .x-resizable-handle-south {
  background-position: top;
}

.x-resizable-over .x-resizable-handle-southeast,
.x-resizable-pinned .x-resizable-handle-southeast {
  background-position: top left;
}

.x-resizable-over .x-resizable-handle-northwest,
.x-resizable-pinned .x-resizable-handle-northwest {
  background-position: bottom right;
}

.x-resizable-over .x-resizable-handle-northeast,
.x-resizable-pinned .x-resizable-handle-northeast {
  background-position: bottom left;
}

.x-resizable-over .x-resizable-handle-southwest,
.x-resizable-pinned .x-resizable-handle-southwest {
  background-position: top right;
}

.x-resizable-proxy {
  border: 1px dashed;
  position: absolute;
  overflow: hidden;
  display: none;
  left: 0;
  top: 0;
  z-index: 50000;
}

.x-resizable-overlay {
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 200000;
  opacity: 0;
}
