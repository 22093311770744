@use 'sass:math';

@import 'react-day-picker/lib/style.css';

.Bs .input-daterange {
  max-width: 210px;

  /* must be higher than Ext.WindowMgr and lower than react bootstrap's modal */
  z-index: 1000;

  .input-group-addon {
    padding: 3px 6px;
    width: 1%;
    line-height: 1.166;
    border-left: 0;
    border-right: 0;
  }

  input.form-control {
    box-shadow: none;
    float: none;

    &:hover {
      border-color: $form-text-focus-border-color;
    }
  }

  .has-error input.form-control {
    &:hover {
      border-color: $form-text-invalid-border-color;
    }
  }
}

.Bs {
  .DayPickerInput-Overlay {
    right: 0; // prevents overlay to go over the window
    left: inherit;
    background-color: $date-picker-background-color;
  }

  .DayPicker-wrapper {
    padding: 4px;
    font-size: $button-font-size;
  }

  .DayPicker-Caption {
    text-align: center;
    margin-bottom: math.div($button-text-height, 2);

    > div {
      font-size: $button-font-size;
      font-weight: bold;
    }
  }

  .DayPicker-NavBar {
    position: absolute;
    left: 4px;
    right: 4px;

    button.btn {
      min-width: auto;
      border-radius: $button-border-radius;

      &.previous {
        float: left;
      }

      &.next {
        float: right;
      }
    }
  }

  .DayPicker-Month {
    margin: math.div($button-text-height, 4) 0 0;
  }

  .DayPicker-Weekday {
    color: $text-color;
    font-size: $button-font-size;

    abbr[title] {
      font-weight: bold;
      cursor: auto;
      text-decoration: none;
    }
  }

  .DayPicker-Weekday,
  .DayPicker-Day {
    padding: 9px 8px;
    border-radius: $button-border-radius;
  }

  .DayPicker-Day--today {
    color: $warning-color;
  }

  .DayPicker-Day--disabled {
    color: $date-picker-disabled-font-color;
  }

  .DayPicker:not(.DayPicker--interactionDisabled)
    .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    @if $button-hover-background != $button-hover-background-color {
      background: $button-hover-background;
    }

    background-color: $button-hover-background-color;
    color: $button-hover-font-color;
  }

  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: $date-picker-table-button-font-color;
    background: $button-background;

    &:hover {
      @if $button-hover-background != $button-hover-background-color {
        background: $button-hover-background;
      }

      background-color: $button-hover-background-color;
      color: $button-hover-font-color;
    }
  }

  .DayPicker-Day--start:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside),
  .DayPicker-Day--end:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    color: $date-picker-selected-font-color;
    background: $date-picker-selected-background-color;

    &:hover {
      @if $button-primary-hover-background != $button-primary-hover-background-color {
        background: $button-primary-hover-background;
      }

      background-color: $button-primary-hover-background-color;
      color: $button-primary-hover-font-color;
    }
  }
}
