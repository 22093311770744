.x-box-tl {
  background: transparent no-repeat url('~box/corners.png') 0;
}

.x-box-tc {
  height: 8px;
  background: transparent repeat-x url('~box/tb.png') 0;
  overflow: hidden;
}

.x-box-tr {
  background: transparent no-repeat url('~box/corners.png') right -8px;
}

.x-box-ml {
  background: transparent repeat-y url('~box/l.png') 0;
  padding-left: 4px;
  overflow: hidden;
}

.x-box-mc {
  background: repeat-x 0 -16px;
  padding: 4px 10px;
  background-color: $neutral-color;
  background-image: url('~box/tb.png');
  color: $button-font-color;
  font-size: $font-size;
  // TODO just for debugging. remove if nowhere used.
  border: 10px #ff4136 dotted;

  h3 {
    margin: 0 0 4px;
    zoom: 1;
    font-size: 14px;
    font-weight: bold;
  }
}

.x-box-mr {
  background: transparent repeat-y url('~box/r.png') right;
  padding-right: 4px;
  overflow: hidden;
}

.x-box-bl {
  background: transparent no-repeat url('~box/corners.png') 0 -16px;
}

.x-box-bc {
  background: transparent repeat-x url('~box/tb.png') 0 -8px;
  height: 8px;
  overflow: hidden;
}

.x-box-br {
  background: transparent no-repeat url('~box/corners.png') right -24px;
}

.x-box-bl,
.x-box-tl {
  padding-left: 8px;
  overflow: hidden;
}

.x-box-br,
.x-box-tr {
  padding-right: 8px;
  overflow: hidden;
}

.x-box-blue {
  .x-box-bl,
  .x-box-br,
  .x-box-tl,
  .x-box-tr {
    background-image: url('~box/corners-blue.png');
  }

  .x-box-bc,
  .x-box-mc,
  .x-box-tc {
    background-image: url('~box/tb-blue.png');
  }

  .x-box-mc {
    background-color: #c3daf9;

    h3 {
      color: #17385b;
    }
  }

  .x-box-ml {
    background-image: url('~box/l-blue.png');
  }

  .x-box-mr {
    background-image: url('~box/r-blue.png');
  }
}
