@mixin required-marker($icon-color: $icon-required-color) {
  @include icon-font();
  @include icon-font-size();
  @include icon('star');
  @include icon-color($icon-color);

  display: inline-block;
  vertical-align: top;
  margin-left: 4px;

  &::before {
    cursor: default;
  }
}
