$icon-font-family: 'SMX';

$icon-explorer-color: #353839 !default;
$icon-button-and-menu-color: #fff !default;

$icon-success-color: #348c20 !default;
$icon-danger-color: #ed1c28 !default;
$icon-warning-color: $warning-color !default;
$icon-required-color: #f7d000 !default;
$icon-help-color: #549fd7 !default;
$icon-information-color: $information-color !default;
