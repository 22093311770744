.x-tab-panel,
.x-tab-panel-body,
.x-tab-panel-bwrap {
  overflow: hidden;
}

.x-tab-panel-footer {
  border: 1px solid;
  padding-top: 2px;
}

.x-tab-panel-bbar .x-toolbar,
.x-tab-panel-tbar .x-toolbar {
  border: 1px $panel-border-color solid;
  border-top: 0;
  overflow: hidden;
  padding: 2px;
}

.x-tab-panel-footer,
.x-tab-panel-header {
  overflow: hidden;
  border-color: $tab-active-background-color;
  border-style: solid;
}

.x-tab-panel-body {
  background-color: $panel-background-color;

  @if not $panel-flat {
    border: 1px $panel-border-color solid;
  }

  @if $panel-flat {
    &.left-border {
      border-left: 1px $panel-border-color solid;
    }
  }
}

.x-tab-panel-body-top {
  border-top: 0;
}

.x-tab-panel-body-bottom {
  border-bottom: 0;
}

.x-tab-panel-footer-plain {
  background: transparent !important;
  border-width: 0 !important;
  padding-top: 0 !important;
}

.x-tab-panel-header-plain {
  background: transparent !important;
  border-width: 0 !important;
  padding-bottom: 0 !important;
}
