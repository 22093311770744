.rule-detail-panel textarea[readonly] {
  background: $form-input-read-only-background-color;
  pointer-events: none;

  &:focus {
    border-color: $form-text-border-color;
  }
}

.smartrules-conditions,
.smartrules-actions {
  .smartrules-icon {
    @include icon-font();
  }

  .smartrules-icon-bar {
    .x-btn-text-icon {
      min-width: auto;
    }

    .smartrules-icon {
      @include icon-size($size: $smartrules-icon-size);
      @include icon-color($smartrules-icon-color);

      height: 32px;
      cursor: move;
      border: 1px solid transparent;
      margin: 0 0 3px; // so that box shadow isn't cut off on hover
      transition-property: color, background, background-color, fill;
      transition-duration: 0.5s;

      &::before {
        @include smartrules-icon-frame;
      }

      .x-panel-body {
        background: transparent;
      }

      svg {
        height: $smartrules-icon-size;
        width: $smartrules-icon-size;
      }

      &:hover {
        @include material-shadow();

        border-color: $smartrules-icon-color;
        background-color: $smartrules-icon-hover-background;

        svg {
          background-color: $smartrules-icon-hover-background;
        }
      }

      // styles for when active, being dragged
      &.smartrules-dd-active-source {
        @include icon-color($smartrules-icon-active-color);

        border-color: $smartrules-icon-active-color;
        background: #fff;

        &::before,
        svg {
          background: $smartrules-icon-active-background;
          fill: $smartrules-icon-active-color !important;
          transition-property: color, background, background-color, fill;
          transition-duration: 0.5s;
        }
      }
    }

    .x-btn {
      margin-top: 7px;
      position: absolute;
    }
  }

  // draggable area below icon bar, can reuse some of the icons
  // just smaller and in grey
  .smartrules-group {
    .smartrules-icon {
      @include icon-font();
      @include icon-size($size: 16px);
      @include icon-color($smartrules-panel-icon-color);

      height: $button-icon-font-size;

      svg {
        width: $button-icon-font-size;
        height: $button-icon-font-size;
        fill: $smartrules-panel-icon-color;
      }
    }
  }

  textarea.x-form-field {
    line-height: $form-input-line-height;
    margin: 0;
  }
}

.smartrule-designer .smartrules-icon-disabled.smartrules-icon {
  cursor: default;
  opacity: 0.35;

  &:hover {
    @include box-shadow(none);

    border-color: transparent;
    background: transparent;

    svg {
      background: transparent;
    }
  }
}

.smartrules-conditions {
  cursor: default;

  .smartrules-icon-bar {
    @include smartrules-condition-icons;
  }

  .x-btn {
    button.smartrules-open-lists,
    button.smartrules-open-templates {
      @include smartrules-icon-frame;

      border-top: 2px solid transparent;

      svg {
        width: $smartrules-icon-size;
        height: 18px;
        fill: $button-icon-color;
      }
    }

    &.x-btn-over {
      button.smartrules-open-lists,
      button.smartrules-open-templates {
        svg {
          fill: $button-hover-font-color;
        }
      }
    }
  }
}

.smartrules-actions {
  cursor: default;

  &,
  .smartrules-icon-bar {
    @include smartrules-action-icons;
  }
}

.smartrules-send-notification .smartrules-map label {
  padding-right: 1em;
  text-align: right;
}

td.x-grid3-td-hasSmartRule .hasSmartRule {
  @include icon-font();
  @include icon-size($button-icon-font-size);
  @include icon('smx-smartrules');
  @include icon-color($button-icon-color);

  height: $button-icon-font-size;
  width: $button-icon-font-size;
  color: $button-icon-color;
}

.smartrule-designer .smartrules-panel-button {
  &.x-btn {
    border: 0;
    background-color: $smartrules-panel-button-background-color !important;
  }

  &.x-btn-over {
    @if $smartrules-panel-button-hover-background !=
      $smartrules-panel-button-hover-background-color
    {
      background: $smartrules-panel-button-hover-background;
    }

    background-color: $smartrules-panel-button-hover-background-color;
  }
}

.smartrules-nested-group .smartrules-group-body {
  margin-left: 40px;
  margin-bottom: 3px;
  padding-left: 5px;
  border-left: 3px solid $form-text-border-color;
}

.smartrules-panel-button .smartrules-nestable-button-icon,
.smartrules-nested-group .smartrules-panel-button .smartrules-nestable-button-icon,
.smartrules-panel-button.nesting .smartrules-nestable-button-icon,
.smartrules-nested-group
  .smartrules-panel-button.nesting
  .smartrules-nestable-button-icon,
.smartrules-panel-button .smartrules-draggable-button-icon,
.smartrules-nested-group .smartrules-panel-button .smartrules-draggable-button-icon,
.smartrules-panel-button .smartrules-delete-button-icon,
.smartrules-nested-group .smartrules-panel-button .smartrules-delete-button-icon {
  @include icon-font();
  @include icon-size($size: 16px);
  @include icon-color($smartrules-panel-button-icon-color);
}

.smartrules-panel-button.x-btn-over .smartrules-nestable-button-icon,
.smartrules-nested-group
  .smartrules-panel-button.x-btn-over
  .smartrules-nestable-button-icon,
.smartrules-panel-button.nesting.x-btn-over .smartrules-nestable-button-icon,
.smartrules-nested-group
  .smartrules-panel-button.nesting.x-btn-over
  .smartrules-nestable-button-icon,
.smartrules-panel-button.x-btn-over .smartrules-draggable-button-icon,
.smartrules-nested-group
  .smartrules-panel-button.x-btn-over
  .smartrules-draggable-button-icon,
.smartrules-panel-button.x-btn-over .smartrules-delete-button-icon,
.smartrules-nested-group
  .smartrules-panel-button.x-btn-over
  .smartrules-delete-button-icon {
  @include icon-color($smartrules-panel-button-hover-icon-color);
}

.smartrules-panel-button .smartrules-nestable-button-icon,
.smartrules-nested-group .smartrules-panel-button .smartrules-nestable-button-icon {
  @include icon('plus');

  opacity: $smartrules-panel-button-opacity;
}

.smartrules-panel-button.nesting .smartrules-nestable-button-icon,
.smartrules-nested-group
  .smartrules-panel-button.nesting
  .smartrules-nestable-button-icon {
  @include icon('minus');

  opacity: $smartrules-panel-button-opacity;
}

.smartrules-panel-button .smartrules-draggable-button-icon,
.smartrules-nested-group .smartrules-panel-button .smartrules-draggable-button-icon {
  @include icon('drag-position');

  opacity: $smartrules-panel-button-opacity;
}

.smartrules-panel-button .smartrules-delete-button-icon,
.smartrules-nested-group .smartrules-panel-button .smartrules-delete-button-icon {
  @include icon('remove');

  opacity: $smartrules-panel-button-opacity;
}

.smartrule.hover .smartrules-panel-button .smartrules-nestable-button-icon,
.smartrules-nested-group
  .smartrules-group-header.hover
  .smartrules-panel-button
  .smartrules-nestable-button-icon,
.smartrule.hover .smartrules-panel-button .smartrules-delete-button-icon,
.smartrules-nested-group
  .smartrules-group-header.hover
  .smartrules-panel-button
  .smartrules-delete-button-icon {
  opacity: 0.6;
  cursor: pointer;
}

.smartrule.hover .smartrules-panel-button .smartrules-draggable-button-icon,
.smartrules-nested-group
  .smartrules-group-header.hover
  .smartrules-panel-button
  .smartrules-draggable-button-icon {
  opacity: 0.6;
  cursor: move;
}

.smartrule.hover
  .smartrules-panel-button.smartrules-nestable-button.hover
  .smartrules-nestable-button-icon,
.smartrules-nested-group
  .smartrules-group-header.hover
  .smartrules-panel-button.smartrules-nestable-button.hover
  .smartrules-nestable-button-icon,
.smartrule.hover
  .smartrules-panel-button.smartrules-nestable-button.hover.nesting
  .smartrules-nestable-button-icon,
.smartrules-nested-group
  .smartrules-group-header.hover
  .smartrules-panel-button.smartrules-nestable-button.hover.nesting
  .smartrules-nestable-button-icon {
  opacity: 1;
}

.smartrule.hover
  .smartrules-panel-button.smartrules-draggable-button.hover
  .smartrules-draggable-button-icon,
.smartrules-nested-group
  .smartrules-group-header.hover
  .smartrules-panel-button.smartrules-draggable-button.hover
  .smartrules-draggable-button-icon {
  @include icon('drag-position-full');
  @include icon-font();

  opacity: 1;
}

.smartrule.hover
  .smartrules-panel-button.smartrules-delete-button.hover
  .smartrules-delete-button-icon,
.smartrules-nested-group
  .smartrules-group-header.hover
  .smartrules-panel-button.smartrules-delete-button.hover
  .smartrules-delete-button-icon {
  @include icon('remove-open');
  @include icon-font();

  opacity: 1;
}

.x-dd-drag-ghost {
  @include box-shadow(2px 2px 4px $button-border-color);
  @include smartrules-action-icons;
  @include smartrules-condition-icons;

  opacity: 1;
  border: 0;

  .smartrules-drag-tooltip {
    background-color: $toolbar-button-background;
    border-radius: 4px 0 0 4px;
    border: 1px solid $toolbar-button-background;
    padding: 2px;
  }

  p {
    display: inline-block;
    vertical-align: top;
    line-height: 28px;
  }

  .smartrules-icon {
    @include icon-font();
    @include icon-size(22px);
    @include icon-color($smartrules-icon-color);

    display: inline-block;
    height: 22px;
    line-height: 22px;

    &::before {
      width: 22px;
      margin-right: 4px;
    }
  }
}

.smartrules-condition .smartrules-joinword,
.smartrules-action .smartrules-joinword,
.smartrules-group .smartrules-joinword {
  visibility: visible;
  text-transform: uppercase;
  color: $form-label-color;
  font-weight: bold;
  letter-spacing: -1px;
  font-size: 0.82em;
  line-height: 20px;
}

.smartrules-conditions .smartrules-joinword .x-panel-body::after,
.smartrules-actions .smartrules-joinword .x-panel-body::after {
  content: ' ...';
}

.smartrules-action {
  label {
    line-height: 2.4em;
  }

  .smartrules-actions .smartrules-icon .x-panel-body {
    opacity: 0.75;
    border: 0;
    cursor: default;
    visibility: visible;
    width: 28px;
    height: 28px;
  }
}

.smartrules-icon-bar {
  &,
  > .x-panel-bwrap > .x-panel-body {
    background-color: $smartrules-icon-bar-background-color;
  }
}

.smartrules-condition.x-dd-drop-above,
.smartrules-condition.prospect.x-dd-drop-above,
.smartrules-condition.prospect.x-dd-drop-above:last-child,
.smartrules-action.x-dd-drop-above,
.smartrules-action.prospect.x-dd-drop-above,
.smartrules-action.prospect.x-dd-drop-above:last-child,
.smartrules-nested-group.x-dd-drop-above,
.smartrules-nested-group.prospect.x-dd-drop-above,
.smartrules-nested-group.prospect.x-dd-drop-above:last-child {
  border-top: $smartrules-prospect-hover-border;
}

.smartrules-condition.x-dd-drop-below,
.smartrules-condition.prospect.x-dd-drop-below,
.smartrules-condition.prospect.x-dd-drop-below:last-child,
.smartrules-action.x-dd-drop-below,
.smartrules-action.prospect.x-dd-drop-below,
.smartrules-action.prospect.x-dd-drop-below:last-child,
.smartrules-nested-group.x-dd-drop-below,
.smartrules-nested-group.prospect.x-dd-drop-below,
.smartrules-nested-group.prospect.x-dd-drop-below:last-child {
  border-bottom: $smartrules-prospect-hover-border;
}

.smartrules-condition.non-prospect,
.smartrules-action.non-prospect,
.smartrules-nested-group.non-prospect,
.non-prospect + .prospect,
.smartrules-condition,
.smartrules-action,
.smartrules-nested-group {
  border-top: $smartrules-nonprospect-border;
}

.smartrules-condition.non-prospect:last-child,
.smartrules-action.non-prospect:last-child,
.smartrules-nested-group.non-prospect:last-child,
.smartrules-condition:last-child,
.smartrules-action:last-child,
.smartrules-nested-group:last-child {
  border-bottom: $smartrules-nonprospect-border;
}

.smartrules-group-header .smartrules-condition,
.smartrules-group-header .smartrules-action,
.smartrules-condition.prospect.x-dd-drop-below + .smartrules-condition,
.smartrules-action.prospect.x-dd-drop-below + .smartrules-action,
.smartrules-condition.prospect.x-dd-drop-below + .smartrules-group,
.smartules-action.prospect.x-dd-drop-below + .smartrules-group,
.smartrules-action.prospect.x-dd-drop-below + .smartrules-group,
.smartrules-group.prospect.x-dd-drop-below + .smartrules-condition,
.smartrules-group.prospect.x-dd-drop-below + .smartrules-action,
.smartrules-group.prospect.x-dd-drop-below + .smartrules-group {
  border-top: 0;
}

.smartrules-condition.prospect,
.smartrules-action.prospect,
.smartrules-nested-group.prospect {
  border-top: $smartrules-prospect-border;
}

.smartrules-condition.prospect:last-child,
.smartrules-action.prospect:last-child,
.smartrules-nested-group.prospect:last-child {
  border-bottom: $smartrules-prospect-border;
}

.smartrules-target-zone {
  &.smartrules-target-hover.prospect .x-panel-body {
    background-color: $smartrules-dropzone-color;
    border: 3px solid $smartrules-dropzone-color;
  }

  .smartrules-nested-group &.smartrules-target-hover.prospect .x-panel-body {
    border-radius: 8px;
  }

  .x-panel-body {
    text-transform: uppercase;
    color: $form-label-color;
    font-size: 0.82em;
    font-weight: bold;
    border: 3px $toolbar-button-background solid;
  }

  &.prospect .x-panel-body {
    background-color: $panel-background-color;
    border: $smartrules-prospect-border;
  }
}

.smartrules-source-status {
  @include icon-font();
  @include icon-size(16px);

  line-height: 1.8;
  padding: 5px 10px 5px 34px;
}

.smartrules-source-ok .smartrules-source-status {
  @include icon-color($icon-success-color);
  @include icon('checkmark');
}

.smartrules-source-error .smartrules-source-status {
  @include icon-color($icon-danger-color);
  @include icon('general-warning');
}

.smartrules-slash {
  font-size: 1.4em;
  text-align: center;
}

.smartrules-matcher-invalid .x-form-field {
  background-color: $form-text-invalid-background-color;
  background-image: none;
  border-color: $form-text-invalid-border-color;
}

.smartrules-matcher .x-box-item {
  position: absolute;
}

.smartrules-condition-combo {
  line-height: 19px;
}

.smartrules-map {
  border-left: 3px solid $form-text-border-color;
}

.smx-notification-headers {
  padding: 3px 0 2px 8px;
  text-transform: uppercase;
  font-size: 9px;
}

.smx-smartrules-actions {
  ul {
    margin-left: 1em;

    &.smx-smartrules-action {
      list-style: inside none;
    }
  }
}
