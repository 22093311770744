label {
  font-size: $form-item-font-size;
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.form-group,
.row.spacer {
  margin-bottom: $padding-base-vertical * 3;
}

.row.large-spacer {
  margin-bottom: $padding-base-vertical * 12;
}

.form-horizontal {
  clear: both;

  .has-feedback .form-control-feedback {
    right: -20px;
  }
}

.has-feedback .form-control {
  padding: $padding-base-vertical $padding-base-horizontal;
}

.btn {
  font-size: $button-font-size;
  padding: 5px $button-padding-x;
  height: $input-height-base;
  line-height: $input-height-base - 10px;
  min-width: $button-min-width;
  text-transform: $button-text-transform;

  @if $button-flat {
    border: 0;
  }

  @if $button-ripple {
    @include button-ripple;
  }

  &:active,
  &.active {
    box-shadow: none;
  }

  &[disabled],
  &:hover[disabled],
  &:focus[disabled],
  &:active[disabled] {
    opacity: $form-input-disabled-opacity;
  }
}

.btn-lg {
  @include button-size(
    $padding-large-vertical,
    $padding-large-horizontal,
    $font-size-large,
    $line-height-large,
    $btn-border-radius-large
  );
}

.btn-default {
  &:hover:not([disabled]) {
    background: $button-hover-background;
    color: $button-hover-font-color;
  }
}

.btn-primary {
  &:hover {
    background: $button-primary-hover-background;
    color: $button-primary-hover-font-color;

    @if not $button-flat {
      border: $button-border;
    }
  }

  &[disabled],
  &:hover[disabled],
  &:focus[disabled],
  &:active[disabled] {
    background: $button-primary-disabled-background;
    color: $button-primary-disabled-font-color;

    @if not $button-flat {
      border: $button-primary-disabled-border;
    }
  }
}

.required .form-control-feedback {
  @include required-marker();
}

.btn-group > .btn {
  padding: 6px 12px;
}

input[type='radio'],
input[type='checkbox'] {
  margin-top: 0;

  &:focus {
    outline: none;
  }
}

.has-warning .form-control,
.has-success .form-control,
.form-control {
  border-color: $form-border-color;
  box-shadow: none;

  &:focus {
    box-shadow: none;
    border-color: $form-text-focus-border-color;
  }
}

.form-group {
  .control-label {
    cursor: pointer;
  }

  .control-label,
  label.checkbox-inline {
    color: $form-label-color;
  }
}

.checkbox label,
label.checkbox-inline {
  line-height: 13px;
}

.form-control {
  background: transparent;
  box-shadow: none;
  transition: none;
}

// readonly
p.form-control {
  line-height: $form-text-input-height;
  background: $form-input-read-only-background-color;
}

// no gloss on safari
select {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.has-warning .control-label,
.has-success .control-label {
  color: $form-label-color;
}

.has-success .form-control-feedback {
  color: $icon-success-color;
}

.has-error .form-control {
  background: $form-invalid-background-image $form-text-invalid-background-color repeat-x
    bottom;
}

legend {
  width: auto;
  border: 0;
}
