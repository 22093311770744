form.membership {
  .toolbar {
    .loading-indicator {
      float: left;
      margin: 5px 10px;
    }

    .btn-toolbar > .btn {
      min-width: $button-min-width;
    }

    button.btn {
      margin-bottom: 5px;
    }
  }
}
