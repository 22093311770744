.x-row-editor-header {
  height: 2px;
  overflow: hidden;
  background: transparent url('~grid/row-editor-bg.gif') repeat-x 0 0;
}

.x-row-editor-footer {
  height: 2px;
  overflow: hidden;
  background: transparent url('~grid/row-editor-bg.gif') repeat-x 0 -2px;
}

.x-row-editor-body {
  overflow: hidden;
  background: $desktop-background;
  padding-top: 2px;
}

.x-row-editor {
  .x-btns {
    position: absolute;
    top: 28px;
    left: 20px;
    padding-left: 5px;
    background: transparent url('~grid/row-editor-btns.gif') no-repeat 0 0;

    table {
      table-layout: auto;

      .x-table-layout-cell {
        padding-top: 2px;
        padding-right: 7px;
      }
    }

    .x-plain-bwrap {
      padding-right: 5px;
      background: transparent url('~grid/row-editor-btns.gif') no-repeat right -31px;
    }

    .x-plain-body {
      background: transparent url('~grid/row-editor-btns.gif') repeat-x 0 -62px;
      height: 31px;
    }
  }
}
