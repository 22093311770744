$menu-background-color: $neutral-color !default;
$menu-background: radial-gradient(
  lighten($menu-background-color, 10),
  $menu-background-color
) !default;

$menu-font-color: opposite-color($menu-background-color) !default;
$menu-active-background-color: lighten(
  $menu-background-color,
  $active-lightness-diff
) !default;
$menu-active-font-color: opposite-color($menu-active-background-color) !default;
$menu-active-border-color: darken(
  $menu-active-background-color,
  $border-darkness-diff
) !default;

$menu-floating-border-color: $form-border-color;

$menu-separator-background-color: darken($menu-background-color, 15);
$menu-separator-border-color: lighten($menu-separator-background-color, 7);
