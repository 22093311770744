.mail-detail {
  .field {
    .key {
      color: $form-label-color;
      display: inline-block;
    }

    .children {
      display: inline-block;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      vertical-align: middle;
      color: $text-color;
    }
  }

  .mail-summary {
    font-family: monospace;

    .field {
      margin-top: 3px;
      margin-bottom: 3px;

      .key {
        width: 10em;
        margin-right: 10px;
        opacity: 0.9;
      }

      .children {
        width: calc(100% - 10em - 10px);
      }
    }
  }

  .panel .panel-heading {
    position: relative;

    .toggler {
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  .mail-preview-panel {
    .panel-heading {
      padding: 0;

      .panel-title,
      .attachments-panel {
        padding: 5px;
      }

      .toggler {
        top: 0;
        right: 0;
      }
    }

    .alert {
      margin: 0;
      border: 0;
      border-top: 1px $panel-primary-border-color solid;
    }

    .mail-preview-header {
      position: relative;
      min-height: 2.8em;

      .field {
        line-height: 140%;
        padding-right: 9.5em;

        .key {
          width: 4.5em;
          margin-right: 8px;
          text-align: right;
          opacity: 0.8;
        }

        .children {
          width: calc(100% - 4.5em - 8px);
        }

        &.date {
          position: absolute;
          right: 0;
          bottom: 0;
          text-align: right;
          padding: 0;
          max-width: 9em;

          .children {
            width: auto;
          }
        }
      }
    }

    .attachments-panel {
      &.panel {
        box-shadow: none;
        border: 0;
        border-top: 1px $panel-primary-border-color solid;

        .panel-heading {
          background: transparent;
          box-shadow: none;

          .panel-title {
            a {
              text-decoration: underline;

              &::before {
                float: left;
                margin-right: 0.6em;
                content: '\f48b';
                margin-top: 3px;
                font-size: 10px;
              }

              &.collapsed {
                &::before {
                  content: '\f488';
                }
              }
            }
          }
        }

        .panel-collapse {
          .panel-body {
            border: 0;
            padding-top: 0;

            ul {
              list-style-type: none;
            }

            small {
              font-size: 85%;
              padding-left: 0.3em;
            }
          }
        }
      }
    }

    iframe.htmlBody {
      width: 100%;
      border: 0;
    }

    .textBody {
      font-family: monospace;
      padding: 3px;
      background: transparent;
      border: 0;
      margin: 0;
    }
  }
}

.guard {
  .row {
    .btn-lg {
      height: auto;
      padding: 16px;
      border: 0;

      &:active {
        box-shadow: none;
      }

      svg {
        width: 40px;
        height: 40px;
      }

      p {
        margin-bottom: 0;
        padding-bottom: 0;
        white-space: normal;
      }
    }
  }
}

#mail-source {
  font-family: monospace;
  max-height: 80vh;
  overflow-y: auto;
  white-space: pre;
}
