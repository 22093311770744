#next-desktop-footer {
  padding-top: 3px;
  background-color: $footer-background-color;
  border-top: 1px solid $footer-top-border-color;

  .x-btn button {
    padding-left: $button-padding-x;
    padding-right: $button-padding-x;
  }

  .x-btn-pressed {
    background-color: $footer-button-active-background-color;

    button {
      color: $footer-button-active-font-color;
    }

    @if not $button-flat {
      border: $footer-button-active-border;
    }

    @if $footer-button-active-background != $footer-button-active-background-color {
      background: $footer-button-active-background;
    }
  }
}

#version-info {
  background: darken($footer-background-color, 14);
  border: 0;
  border-left: 1px dotted darken($footer-background-color, 18);
  height: 34px !important;

  &.x-btn {
    border-radius: 0;

    button {
      color: $footer-button-font-color;
    }
  }
}
