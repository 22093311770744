@font-face {
  font-family: $icon-font-family;
  src: url('~SMX.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.Bs .btn,
.x-menu-item,
.x-viewport .x-btn {
  &.blocked,
  &.next-refresh,
  &.play,
  .MailRelayHost-checkStatus,
  .add-list,
  .blocked,
  .delete-domain,
  .delete-list,
  .edit-domains,
  .export-csv,
  .export-html,
  .export-menu,
  .export-pdf,
  .insert-macro,
  .list-SMX-Customer,
  .list-SMX-Distributor,
  .list-SMX-InvoiceRun,
  .list-SMX-MailRelayHost,
  .list-SMX-Note,
  .list-SMX-OutboundDomain,
  .list-SMX-Product,
  .list-SMX-Report,
  .list-SMX-Reseller,
  .list-SMX-ScrubbingDomain,
  .list-SMX-TrustedIp,
  .list-SMX-User,
  .new-SMX-Agreement,
  .new-SMX-Customer,
  .new-SMX-Distributor,
  .new-SMX-HostingDomain,
  .new-SMX-InvoiceRun,
  .new-SMX-MailRelayHost,
  .new-SMX-Note,
  .new-SMX-OutboundDomain,
  .new-SMX-Reseller,
  .new-SMX-ScrubbingDomain,
  .new-SMX-SmartRules-ListManagement,
  .new-SMX-SmartRules-Rule,
  .new-SMX-TrustedIp,
  .new-SMX-User,
  .next-refresh,
  .open-SMX-Agreement,
  .open-SMX-AgreementPriceComponent,
  .open-SMX-Customer,
  .open-SMX-Distributor,
  .open-SMX-HostingDomain,
  .open-SMX-InvoiceItem,
  .open-SMX-InvoiceRun,
  .open-SMX-MailRelayHost,
  .open-SMX-Note,
  .open-SMX-OutboundDomain,
  .open-SMX-PriceComponent,
  .open-SMX-Product,
  .open-SMX-Report,
  .open-SMX-Reseller,
  .open-SMX-ResellerAgreement,
  .open-SMX-ScrubbingDomain,
  .open-SMX-SmartRules-ListManagement,
  .open-SMX-SmartRules-Rule,
  .open-SMX-Subscription,
  .open-SMX-TrustedIp,
  .play,
  .remove-SMX-Distributor,
  .remove-SMX-HostingDomain,
  .remove-SMX-InvoiceItem,
  .remove-SMX-MailRelayHost,
  .remove-SMX-Reseller,
  .remove-SMX-ResellerAgreement,
  .remove-SMX-ScrubbingDomain,
  .remove-SMX-SmartRules-ListManagement,
  .remove-SMX-SmartRules-Rule,
  .remove-SMX-Subscription,
  .remove-SMX-TrustedIp,
  .remove-SMX-User,
  .remove-SMX-brightmail-Configuration,
  .reorder-policy-sets,
  .search-event,
  .show-errors,
  .whitelist {
    @include icon-font();
    @include icon-size($size: 16px);
  }

  .new-SMX-Subscription {
    @include icon('newspaper');
  }

  .new-SMX-Support {
    @include icon('support');
  }

  .add-list,
  .insert-macro,
  .new-SMX-Agreement,
  .new-SMX-Customer,
  .new-SMX-Distributor,
  .new-SMX-HostingDomain,
  .new-SMX-MailRelayHost,
  .new-SMX-Note,
  .new-SMX-OutboundDomain,
  .new-SMX-Reseller,
  .new-SMX-ScrubbingDomain,
  .new-SMX-SmartRules-ListManagement,
  .new-SMX-SmartRules-Rule,
  .new-SMX-TrustedIp,
  .new-SMX-User {
    @include icon('open');
  }

  &.blocked,
  .blocked {
    @include icon('blocked');
  }

  &.play,
  .play {
    @include icon('play');
  }

  .new-SMX-InvoiceRun {
    @include icon('invoice-run');
  }

  .open-SMX-Subscription {
    @include icon('newspaper');
  }

  .open-SMX-Vendor {
    @include icon('vendor');
  }

  .open-SMX-Distributor {
    @include icon('distributor');
  }

  .open-SMX-Reseller {
    @include icon('reseller');
  }

  .open-SMX-Customer {
    @include icon('customer');
  }

  .open-SMX-Report {
    @include icon('play');
  }

  .open-SMX-User {
    @include icon('smx-user-management');
  }

  .open-SMX-Agreement,
  .open-SMX-AgreementPriceComponent,
  .open-SMX-HostingDomain,
  .open-SMX-Invoice,
  .open-SMX-InvoiceItem,
  .open-SMX-InvoiceRun,
  .open-SMX-MailRelayHost,
  .open-SMX-Note,
  .open-SMX-OutboundDomain,
  .open-SMX-PriceComponent,
  .open-SMX-Product,
  .open-SMX-ResellerAgreement,
  .open-SMX-ScrubbingDomain,
  .open-SMX-SmartRules-ListManagement,
  .open-SMX-SmartRules-Rule,
  .open-SMX-TrustedIp,
  .open-SMX-message {
    @include icon('open');
  }

  .copy-SMX-HostingDomain {
    @include icon('newspaper');
  }

  .list-SMX-Customer,
  .list-SMX-Distributor,
  .list-SMX-InvoiceRun,
  .list-SMX-MailRelayHost,
  .list-SMX-Note,
  .list-SMX-OutboundDomain,
  .list-SMX-Product,
  .list-SMX-Report,
  .list-SMX-Reseller,
  .list-SMX-ScrubbingDomain,
  .list-SMX-TrustedIp,
  .list-SMX-User {
    @include icon('bulleted-list');
  }

  .list-SMX-Subscription {
    @include icon('newspaper');
  }

  .delete-list,
  .remove-SMX-Distributor,
  .remove-SMX-HostingDomain,
  .remove-SMX-InvoiceItem,
  .remove-SMX-MailRelayHost,
  .remove-SMX-Reseller,
  .remove-SMX-ResellerAgreement,
  .remove-SMX-ScrubbingDomain,
  .remove-SMX-SmartRules-ListManagement,
  .remove-SMX-SmartRules-Rule,
  .remove-SMX-Subscription,
  .remove-SMX-TrustedIp,
  .remove-SMX-User,
  .remove-SMX-brightmail-Configuration {
    @include icon('remove');
  }

  .copy-SMX-SmartRules-RuleManagement,
  .copy-hosting-domain-name {
    @include icon('copy');
  }

  &.next-refresh,
  .next-refresh {
    @include icon('loop');
  }

  .export-SMX-Report,
  .export-menu {
    @include icon('download');
  }

  .export-csv {
    @include icon('document');
  }

  .export-html {
    @include icon('html-source');
  }

  .export-pdf {
    @include icon('pdf');
  }

  .delete-domain {
    @include icon('remove');
  }

  .reorder-policy-sets {
    @include icon('drag-position');
  }

  .edit-domains {
    @include icon('pencil');
  }

  .search-event {
    @include icon('search');
  }

  .next-logout {
    @include icon('logout');
  }

  .next-refresh {
    @include icon('loop');
  }

  .MailRelayHost-checkStatus {
    @include icon('monitor');
  }

  .show-errors {
    @include icon('document');
  }

  .add-inbound-policy-set {
    @include icon('smartrules-condition-recipient');
  }

  .add-outbound-policy-set {
    @include icon('smartrules-condition-sender');
  }

  .whitelist {
    @include icon('checkmark-circle');
  }

  .blacklist {
    @include icon('cancel-circle');
  }

  .x-toolbar-more-icon {
    @include icon-size($size: 8px);
    @include icon('fastright');

    background: transparent !important;
  }

  .x-toolbar-more-icon::before {
    line-height: $button-icon-font-size;
  }
}

.icon-cancel,
.icon-confirm,
.icon-csv,
.icon-password,
.icon-reset,
.icon-upgrade {
  @include icon-font();
  @include icon-size();
}

.icon-upgrade {
  @include icon('arrow-up');
}

.icon-confirm {
  @include icon('checkmark');
}

.icon-cancel {
  @include icon('close');
}

.icon-reset {
  @include icon('back');
}

.icon-csv {
  @include icon('document');
}

.icon-search {
  @include icon('search');
}

.icon-password {
  @include icon('key');
}

.icon-login {
  @include icon('login');
}
