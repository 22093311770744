@use "sass:math";

.tab-pane {
  padding: math.div($grid-gutter-width, 2);

  > .panel {
    margin: 0;
    border: 0;
  }
}

.nav-tabs {
  padding: $padding-large-vertical $padding-large-vertical 0 3px;

  @if $tab-strip-background != $tab-strip-background-color {
    background: $tab-strip-background;
  }

  background-color: $tab-strip-background-color !important;

  li {
    a {
      color: $tab-font-color;
      padding: 6px 12px;
    }

    a,
    a:hover {
      border: 1px $tab-border-color solid;
      border-bottom-color: transparent;
    }

    a:hover {
      background-color: $tab-hover-background-color;
    }

    &.active {
      font-weight: bold;
    }
  }

  > li > a {
    margin-right: 4px;
  }
}
