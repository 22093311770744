$font-family: arial, tahoma, helvetica, sans-serif;
$font-size: 11px !default;
$font-size-large: 13px !default;

$base-color: #ccdfef !default;
$neutral-color: #eee !default;
$gradient-darkness-diff: 7 !default;
$border-darkness-diff: 14 !default;
$hover-lightness-diff: 9 !default;
$active-lightness-diff: 5 !default;

$logo-image: '' !default;
$background-image: '' !default;
$login-image: '' !default;
$title-right-logo-image: '' !default;
$powered-by: true !default;
$button-flat: false !default;
$use-gradients: true !default;
$window-flat: false !default;
$panel-flat: false !default;

$information-color: #00d !default;
$information-border-color: #ccf !default;
$information-background-color: #ededff !default;

$warning-color: #930 !default;
$warning-border-color: #ffb699 !default;
$warning-background-color: #feefb3 !default;

$mask-background-color: #fff !default;

$link-color: $information-color !default;
$text-color: #333 !default;
