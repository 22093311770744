#next-desktop-left {
  border-right: 1px solid $tab-border-color;

  .x-panel-header,
  form {
    border: 0;
  }

  // removes double borders in left explorer
  .x-tree > .x-panel-bwrap > .x-panel-body,
  > .x-panel-bwrap > .x-panel-body {
    border: 0;
  }
}

#next-desktop-center {
  overflow: auto;
  background: $desktop-background;
}

#next-desktop-windows {
  background: transparent none;
  height: 28px;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 12001;
}

#next-desktop-left-xcollapsed {
  width: 22px;
}

#next-desktop-header {
  min-width: 840px;

  @if $desktop-border-bottom-width != 0 {
    border-bottom: $desktop-border-bottom-width solid $header-border-color;
  }

  @if $title-background-color == transparent {
    @if $header-background != $header-background-color {
      background: $header-background;
    }

    background-color: $header-background-color !important;
  }

  @if $header-border-bottom-width != 0 {
    border-bottom-width: $header-border-bottom-width;
    border-bottom-style: solid;
  }

  @if $title-right-logo-image != '' {
    background-color: $title-background-color !important;
    background-image: $title-right-logo-image;
    background-position: top right;
    background-repeat: no-repeat;
  }

  .x-panel-body {
    background: none;
  }

  .x-toolbar {
    border: 0;
    background: none !important;

    .x-btn {
      border: $header-button-border;
      background: $header-button-background;

      button {
        color: $header-button-font-color;
      }
    }

    .x-btn-over {
      border: $header-button-hover-border;
      background: $header-button-hover-background;

      button {
        color: $header-button-hover-font-color;
      }
    }
  }
}

#next-desktop-launcher-wrap {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;

  @if $title-background-color != transparent {
    @if $header-background != $header-background-color {
      background: $header-background;
    }

    background-color: $header-background-color;
  }
}

#next-desktop-launcher-inner {
  padding: 4px 2px;
}

#next-desktop-launcher-right {
  padding-right: 3px;
}

#next-desktop-launcher-center {
  height: 28px;
}

#next-desktop-launcher {
  .x-toolbar {
    padding: 4px 0 0;
    margin: 0;
  }

  .x-toolbar-cell {
    .xtb-sep {
      display: none;
    }
  }

  .x-btn {
    margin-right: 2px;
  }

  .x-btn-menu-text-wrap button,
  .x-btn-with-menu button {
    padding-right: 0;
  }
}
