.ext-el-mask {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.2;
}

.ext-el-mask-msg {
  z-index: 20001;
  position: absolute;
  top: 0;
  left: 0;
  border: 1px $button-border-color solid;
  padding: 4px;

  div {
    padding: 5px 10px;
    cursor: wait;
    color: $text-color;
    font-size: $font-size;
  }
}

.x-mask-loading div {
  padding-left: 25px;
  background: no-repeat 5px 5px;
  line-height: 16px;
  background-image: url('~grid/loading.gif');
}

.x-masked {
  overflow: hidden !important;

  embed,
  object,
  select {
    visibility: hidden;
  }
}

.x-masked-relative {
  position: relative !important;
}

.ext-el-mask-msg,
.x-mask-loading {
  background-color: $mask-background-color;
}
