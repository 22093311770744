$panel-base-color: $neutral-color !default;
$panel-border-color: #d0d0d0 !default;

$ghost-panel-background-color: #6c6c6c !default;

$panel-header-background-color: $panel-base-color !default;
$panel-header-background: $panel-header-background-color !default;
$panel-header-font-color: invert($panel-header-background-color) !default;
$panel-header-border-color: darken($panel-header-background-color, 20) !default;
$panel-field-width: 220px;

$panel-background-color: $window-body-background-color !default;

$panel-primary-border-color: $tab-large-strip-background-color !default;
$panel-primary-border: $panel-primary-border-color !default;
