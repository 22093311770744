@use "sass:math";

$help-line-height: 1.6;
$help-line-height-computed: floor($font-size-base * $help-line-height);

.panel.help {
  padding: math.div($grid-gutter-width, 2) $grid-gutter-width 0;

  > * {
    line-height: $help-line-height;
  }

  h4:first-child {
    margin-top: 0;
  }

  blockquote {
    font-weight: bold;
    border: 1px $blockquote-border-color solid;
    background-color: lighten($blockquote-border-color, 20);
    padding: math.div($help-line-height-computed, 2) $help-line-height-computed;

    p:last-child {
      margin-bottom: 0;
    }
  }

  // this to override legacy selector .x-window-body p, .x-panel-body p
  p {
    padding: 0;
    margin: 0 0 math.div($line-height-computed, 2);
  }

  ul {
    list-style-type: disc;
    padding-left: 2 * $grid-gutter-width;
  }
}

.help-block {
  color: $help-text-color;
  font-size: $form-help-font-size;
  height: $form-help-font-size; // needed so that on empty texts, height remains unchanged

  span {
    display: block;
    padding-bottom: $padding-base-vertical;
  }
}

.has-success .help-block,
.has-warning .help-block {
  color: $help-text-color;
}

.has-error .help-block {
  color: $warning-color;
}
