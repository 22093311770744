.integration-panel {
  padding: 3px;
}

fieldset.applications {
  margin-bottom: 20px;

  dl.dl-horizontal {
    margin-top: 10px;

    dt,
    dd {
      margin-bottom: 10px;
    }

    dt {
      text-align: left;
      width: 210px;
    }

    dd {
      width: 50%;
      min-width: 50em;
      margin-left: 220px;
    }

    a.btn {
      width: 190px;
    }
  }
}
