.tooltip {
  line-height: $qtip-line-height;
  font-size: $qtip-font-size;
  color: $qtip-font-color;

  &.in {
    // we do not want the default opacity of .9 react-bootstrap comes with
    opacity: 1;
  }

  .tooltip-arrow {
    // since there is no react prop to disable it, hide it here to ensure
    // it's aligned with material ui (SCL-2773)

    display: none;
  }

  .tooltip-inner {
    max-width: inherit;
    text-align: left;
    padding: $button-padding-x;
    border-radius: $qtip-border-radius;
    background-color: $qtip-bg-color;

    p,
    pre {
      padding: 4px 0;
      margin: 0;
      border: 0;
      background: none;
      color: inherit;
      white-space: nowrap;
    }

    :first-child {
      padding-top: 0;
      margin-top: 0;
    }

    :last-child {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}
