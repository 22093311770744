.membership-list {
  overflow-y: auto;

  .panel-title {
    .selectAll {
      margin: 0 6px 1px 0;
      vertical-align: middle;
    }

    .memberType {
      display: inline-block;

      h4 {
        margin: 0;
        font-size: $font-size-large;
      }

      h5 {
        margin-bottom: 0;
      }
    }
  }

  .panel-body {
    ul {
      margin-bottom: 0;
      list-style: none;

      li:last-child {
        .form-group {
          margin-bottom: 0;
        }
      }
    }
  }

  .panel:last-child {
    margin-bottom: 0;
  }
}

.modal-dialog .membership-list {
  min-height: 150px;
  height: 30vh;
}
