.x-menu {
  z-index: 15000;
  background: $menu-background repeat-y;

  li {
    line-height: 100%;

    &.x-menu-sep-li {
      font-size: 1px;
      line-height: 1px;
    }

    &.x-menu-item-active {
      padding: 0;
      background-color: $menu-active-background-color;
      border: 1px $menu-active-border-color solid;

      a.x-menu-item {
        color: $menu-active-font-color;
      }
    }

    a {
      text-decoration: none;

      &.x-menu-item {
        -moz-user-select: none;
        -webkit-user-select: ignore;
        cursor: pointer;
        color: $menu-font-color;
        display: block;
        line-height: $button-icon-font-size;
        outline-style: none;
        outline-width: 0;
        padding: 3px 21px 3px 27px;
        position: relative;

        .x-menu-item-icon {
          border: 0;
          height: $button-icon-font-size;
          padding: 0;
          width: $button-icon-font-size;
          position: absolute;
          left: 3px;
          top: 3px;
          margin: 0;
          background-position: center;
        }
      }
    }
  }

  .x-color-palette {
    margin-left: 26px;
    margin-right: 4px;
  }

  .x-menu-scroller {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    height: 8px;
    line-height: 8px;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  .x-menu-scroller-active {
    height: 6px;
    line-height: 6px;
  }
}

.x-menu-floating {
  border-radius: $button-border-radius;
  border: 1px $form-border-color solid;
}

.x-menu-list-item {
  white-space: nowrap;
  display: block;
  font-size: $font-size;
  padding: 1px;
}

.x-menu-item-arrow {
  background: transparent extImage('menu-parent') no-repeat right;
}

.x-menu-focus {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 1px;
  height: 1px;
  line-height: 1px;
  font-size: 1px;
  outline: 0 none;
  -moz-user-select: none;
  -webkit-user-select: ignore;
  overflow: hidden;
  display: block;
}

.x-menu-check-item .x-menu-item-icon {
  background: url('~icons/checkbox-unchecked.png');
}

.x-menu-group-item .x-menu-item-icon {
  background-color: transparent;
  background-image: none;
}

.ext-webkit .x-menu-sep {
  height: 1px;
}

.x-cycle-menu .x-menu-item-checked {
  border: 1px $tab-large-active-border-color dotted !important;
  padding: 0;
  background-color: $button-background-color;
}

.x-menu-list-item-indent {
  padding-left: 27px;
}

.x-menu-nosep {
  background-image: none;
}

.x-menu-sep {
  background-color: $menu-separator-background-color;
  display: block;
  font-size: 1px;
  line-height: 1px;
  margin: 2px 3px;
  border-bottom: 1px $menu-separator-border-color solid;
  overflow: hidden;
}

.x-menu-item-checked {
  .x-menu-item-icon {
    background: url('~icons/checkbox-checked.png');
  }

  .x-menu-group-item .x-menu-item-icon {
    background: transparent extImage('group-checked') no-repeat center;
  }
}

.x-menu-plain {
  background-color: #fff;

  .x-color-palette {
    margin: 0;
    border: 0;
  }
}

.x-menu-scroller-top {
  background: extImage('mini-top');
}

.x-menu-scroller-bottom {
  background: extImage('mini-bottom');
}

.x-menu-list {
  overflow: hidden;
  overflow-y: hidden;
  padding: 2px;

  .x-menu-item-icon {
    @include icon-font();
    @include icon-size($size: $font-size);
  }

  .close .x-menu-item-icon,
  .closeAll .x-menu-item-icon,
  .closeOthers .x-menu-item-icon {
    @include icon('close');
  }

  .minimise .x-menu-item-icon,
  .minimiseAll .x-menu-item-icon {
    @include icon('down');
  }

  .restore .x-menu-item-icon {
    @include icon('up');
  }

  .maximise .x-menu-item-icon {
    @include icon('expand');
  }
}
