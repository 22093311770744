.quarantine-preview {
  > div,
  #message-detail,
  .message-preview-panel {
    height: 100%;
  }

  #message-detail {
    margin-bottom: 0;

    .message-preview-panel,
    .panel-collapse {
      display: flex;
      flex-direction: column;
    }

    .panel-collapse {
      flex-grow: 1;
      overflow-y: auto;
    }

    .panel-body {
      overflow-y: auto;
    }
  }
}
