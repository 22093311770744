.x-tip {
  visibility: hidden;
  text-align: center;
  padding: 6px;
  border-radius: $qtip-border-radius;
  background-color: $qtip-bg-color;
  color: $qtip-font-color;
  font-size: $qtip-font-size;
  line-height: $qtip-line-height;

  &.error .x-tip-body {
    font-weight: bold;
    color: $warning-color;
  }
}

.errorTip .x-tip-body ul {
  list-style-type: disc;
  margin-left: 15px;
}
