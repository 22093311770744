$grid-item-icon-color: #000 !default;

$grid-border-color: #ededed !default;
$grid-border-top-color: #fff !default;
$grid-background-color: #fff !default;
$grid-resize-background-color: #777 !default;

$grid-header-border-left-color: $neutral-color !default;
$grid-header-border-right-color: $panel-border-color !default;
$grid-header-background-color: #f9f9f9 !default;
$grid-header-active-border-color: darken($grid-border-color, 12) !default;

$grid-row-over-background-color: $toolbar-button-hover-background !default;
$grid-summary-background-color: $button-hover-background-color !default;

$grid-selected-row-background-color: #c8dff0 !default;
