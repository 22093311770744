.notification-panel {
  padding: 3px;
  overflow: auto;

  fieldset {
    margin-bottom: 10px;

    h5 {
      font-weight: bold;
      color: $legend-color;
      margin-bottom: 12px;
    }
  }

  fieldset.configuration {
    legend {
      margin-bottom: 10px;
    }
  }

  fieldset.endUser {
    .grid {
      margin-left: 20px;
    }
  }

  fieldset.administrator {
    legend {
      margin-bottom: 10px;
    }
  }

  .footer {
    margin-left: 30px;
    text-align: right;

    .btn + .btn {
      margin-left: 5px;
    }
  }
}
