.info-tooltip-icon {
  @include icon-font();
  @include icon-size($button-icon-font-size);
  @include icon('help');
  @include icon-color($icon-help-color);

  display: inline-block;
  height: $button-icon-font-size;
  position: relative;
  width: $button-icon-font-size;

  &::before {
    cursor: help;
    vertical-align: inherit;
  }
}

.form-group .info-tooltip-icon {
  position: absolute;
  right: -38px;
  top: 5px;
}
