// See bootstrap_variables.scss for what can be changed.
$cursor-disabled: default;

$font-family-base: $font-family;
$font-size-base: $font-size;

$line-height-base: 1.166; // now ~14px, was 1.428571429 (~20px)
$font-size-large: 13px; // ~18px
$line-height-large: 1.1428; // now ~16px, was 1.33 (~24px)
$font-size-small: 10px; // ~12px
$line-height-small: 1.2; // now 12px, was 1.5 (~18px)

$grid-gutter-width: 8px; // 30px

// .btn, .form-control, .radio, .checkbox, .form-horizontal > .control-label, .input-group-addon
$padding-base-vertical: 3px; // 6px
$padding-base-horizontal: 4px; // 12px

// .btn-lg, .input-lg
$padding-large-vertical: 5px; // 10px
$padding-large-horizontal: $button-padding-x; // 16px

// .btn-sm, .input-sm
$padding-small-vertical: 2px; // 5px
$padding-small-horizontal: 4px; // 10px

// .btn-xs
$padding-xs-vertical: 1px; // 1px
$padding-xs-horizontal: 3px; // 5px
$border-radius-base: 0; // 4px
$border-radius-large: 0; // 6px
$border-radius-small: 0; // 3px

$input-color: $form-input-text-color;
$input-border-focus: $form-text-focus-border-color;
$input-height-base: $button-text-height;

$brand-primary: $brand-color; // #428bca

$btn-default-color: $button-font-color;
$btn-default-bg: $button-background;
$btn-default-border: $button-border-color;
$btn-font-weight: $button-font-weight;
$btn-border-radius-base: $button-border-radius;
$btn-border-radius-large: $button-border-radius;
$btn-primary-color: $button-primary-font-color;
$btn-primary-bg: $button-primary-background-color;
$btn-primary-border: $button-primary-border-color;

$panel-body-padding: $padding-large-vertical;
$panel-heading-padding: $padding-large-vertical;
$panel-primary-text: $form-input-text-color;
$panel-primary-heading-bg: $tab-large-strip-background-color;

$badge-font-weight: 200;
$badge-bg: lighten($button-disabled-background-color, 17%);
$badge-color: $form-input-text-color;

$modal-content-border-color: $window-border-color;
$modal-content-fallback-border-color: $window-border-color;
$modal-title-padding: 6px;
$modal-inner-padding: 10px;
$modal-header-border-color: transparent;
$modal-footer-border-color: transparent;

$blockquote-font-size: $font-size-base;
$blockquote-border-color: $header-button-border-color;
$nav-tabs-active-link-hover-color: $tab-font-color;
$help-text-color: $form-label-color;

$state-danger-text: $form-text-invalid-border-color;

$headings-color: $text-color;

$font-size-h5: 12px;
$font-size-h6: 11px;
