.x-html-editor-wrap {
  background-color: $form-text-background-color;
}

.x-html-editor-tb {
  .x-btn-text {
    background: transparent no-repeat;
  }

  .x-edit-bold {
    @include icon-color($button-icon-color);
    @include icon('bold');
  }
}

.x-menu-item img.x-edit-bold {
  @include icon-color($button-icon-color);
  @include icon('bold');
}

.x-html-editor-tb .x-edit-italic,
.x-menu-item img.x-edit-italic {
  @include icon-color($button-icon-color);
  @include icon('italic');
}

.x-html-editor-tb .x-edit-underline,
.x-menu-item img.x-edit-underline {
  @include icon-color($button-icon-color);
  @include icon('underline');
}

.x-html-editor-tb .x-edit-forecolor,
.x-menu-item img.x-edit-forecolor {
  @include icon('foreground-color');
}

.x-html-editor-tb .x-edit-backcolor,
.x-menu-item img.x-edit-backcolor {
  @include icon('background-color');
}

.x-html-editor-tb .x-edit-justifyleft,
.x-menu-item img.x-edit-justifyleft {
  @include icon-color($button-icon-color);
  @include icon('paragraph-left');
}

.x-html-editor-tb .x-edit-justifycenter,
.x-menu-item img.x-edit-justifycenter {
  @include icon-color($button-icon-color);
  @include icon('paragraph-center');
}

.x-html-editor-tb .x-edit-justifyright,
.x-menu-item img.x-edit-justifyright {
  @include icon-color($button-icon-color);
  @include icon('paragraph-right');
}

.x-html-editor-tb .x-edit-insertorderedlist,
.x-menu-item img.x-edit-insertorderedlist {
  @include icon-color($button-icon-color);
  @include icon('numbered-list');
}

.x-html-editor-tb .x-edit-insertunorderedlist,
.x-menu-item img.x-edit-insertunorderedlist {
  @include icon-color($button-icon-color);
  @include icon('bulleted-list');
}

.x-html-editor-tb .x-edit-increasefontsize,
.x-menu-item img.x-edit-increasefontsize {
  @include icon-color($button-icon-color);
  @include icon('font-up');
}

.x-html-editor-tb .x-edit-decreasefontsize,
.x-menu-item img.x-edit-decreasefontsize {
  @include icon-color($button-icon-color);
  @include icon('font-down');
}

.x-html-editor-tb .x-edit-sourceedit,
.x-menu-item img.x-edit-sourceedit {
  @include icon-color($button-icon-color);
  @include icon('html-source');
}

.x-html-editor-tb .x-edit-createlink,
.x-menu-item img.x-edit-createlink {
  @include icon-color($button-icon-color);
  @include icon('link');
}

.x-html-editor-tip .x-tip-bd .x-tip-bd-inner {
  padding: 5px 5px 1px;
}

.x-html-editor-tb .x-toolbar {
  position: static !important;
}
