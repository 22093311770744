// The default color for box shadows
$default-box-shadow-color: #333 !default;

// The default horizontal offset. Positive is to the right.
$default-box-shadow-h-offset: 0 !default;

// The default vertical offset. Positive is down.
$default-box-shadow-v-offset: 0 !default;

// The default blur length.
$default-box-shadow-blur: 5px !default;

// The default spread length.
$default-box-shadow-spread: null !default;

// The default shadow inset: inset or null (for standard shadow).
$default-box-shadow-inset: null !default;

@function default-box-shadow() {
  @return compact(
    if($default-box-shadow-inset, inset, null) $default-box-shadow-h-offset
      $default-box-shadow-v-offset $default-box-shadow-blur $default-box-shadow-spread
      $default-box-shadow-color
  );
}

// Provides cross-browser for Webkit, Gecko, and CSS3 box shadows
// when one or more box shadows are needed.
// Each shadow argument should adhere to the standard css3 syntax
// for the box-shadow property.
@mixin box-shadow($shadow...) {
  $shadow: set-arglist-default($shadow, default-box-shadow());

  box-shadow: $shadow;
}
