$smartrules-panel-button-icon-color: $modal-tool-button-icon-color !default;
$smartrules-panel-button-hover-icon-color: $modal-tool-button-hover-icon-color !default;

$smartrules-panel-button-background-color: transparent !default;
$smartrules-panel-button-hover-background-color: transparent !default;
$smartrules-panel-button-hover-background: transparent !default;
$smartrules-panel-button-opacity: 0.3 !default;

$smartrules-panel-icon-color: #999 !default;

$smartrules-icon-size: 24px;
$smartrules-icon-color: $button-background-color !default;
$smartrules-icon-hover-background: lighten($smartrules-panel-icon-color, 40%) !default;
$smartrules-icon-active-color: lighten($smartrules-icon-color, 25%) !default;
$smartrules-icon-active-background: $smartrules-icon-hover-background !default;

$smartrules-dropzone-color: lighten($icon-success-color, 20%) !default;
$smartrules-prospect-hover-border: 3px solid $smartrules-dropzone-color !default;
$smartrules-prospect-border: 3px dotted $smartrules-dropzone-color;
$smartrules-nonprospect-border: 3px solid #fff;

$smartrules-icon-bar-background-color: $button-background-color !default;
