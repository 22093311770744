button,
input,
textarea {
  font-family: $font-family;
  font-size: $font-size;
}

label {
  color: $form-label-color;
}

.ext-webkit *:focus {
  outline: none !important;
}

.x-form-text {
  background: $form-text-background-color;
  border: 1px $form-text-border-color solid;
}

.ext-mb-input,
.x-form-text {
  line-height: $form-text-input-height;
  height: $form-text-input-height;
  padding: 1px 4px;
  color: $text-color;
}

.x-form-select-one {
  height: 20px;
  line-height: $form-text-input-height;
  vertical-align: middle;
  border: 1px $form-text-border-color solid;
  background-color: $form-text-background-color;
}

.x-form-field-wrap {
  left: 0;
  top: 0;
  text-align: left;
  white-space: nowrap;
  float: left;
  display: table;
  position: static;

  .x-form-field {
    float: left;
  }

  .x-form-date-trigger {
    @include icon('calendar');
  }

  .x-form-clear-trigger {
    @include icon('close');
  }

  .x-form-search-trigger {
    @include icon('search');
  }

  .x-form-combo-trigger {
    @include icon('down');
  }

  .x-form-trigger,
  .x-form-twin-triggers .x-form-trigger {
    vertical-align: middle;
  }

  .x-form-field,
  .x-form-trigger {
    display: table-cell;
  }

  .x-form-trigger {
    width: 20px;
    padding: 0 5px;
  }

  .x-form-twin-triggers .x-form-trigger {
    position: static;
  }

  .x-form-list-trigger-click {
    background-position: -75px 0 !important;
  }

  .x-form-list-trigger-over {
    background-position: -68px 0 !important;
  }

  .x-form-list-trigger {
    background-image: url('~desktop/switchable.png');
    background-position: -51px 0 !important;
  }

  .x-form-textfield-trigger-click {
    background-position: -34px 0 !important;
  }

  .x-form-textfield-trigger-over {
    background-position: -17px 0 !important;
  }

  .x-form-textfield-trigger {
    background-image: url('~desktop/switchable.png');
  }
}

.x-trigger-wrap-focus {
  .x-form-trigger {
    background-position: -51px 0;
  }

  .x-form-trigger-over {
    background-position: -68px 0;
  }

  .x-form-trigger-click {
    background-position: -85px 0;
  }
}

.x-item-disabled {
  opacity: $form-input-disabled-opacity;
  pointer-events: none;
  cursor: default;

  .x-form-trigger-click,
  .x-form-trigger-over {
    background-position: 0 0 !important;
  }
}

.x-form-cb-label,
.x-trigger-noedit,
.x-form-item-label {
  cursor: pointer;
}

.ext-mb-input {
  border: 1px solid transparent;
  margin-top: 4px;
  width: 95%;
}

.ext-mb-input,
textarea.x-form-field {
  background-color: $form-text-background-color;
  border-color: $form-text-border-color;
}

.ext-mb-input:focus,
.x-form-focus,
textarea.x-form-focus {
  border-color: $form-text-focus-border-color;
  background-color: $form-text-focus-background-color;
}

.x-editor {
  visibility: hidden;
  padding: 0;
  margin: 0;
}

.x-form-grow-sizer {
  left: -10000px;
  padding: 8px 3px;
  position: absolute;
  visibility: hidden;
  top: -10000px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  zoom: 1;

  p {
    margin: 0 !important;
    border: 0 !important;
    padding: 0 !important;
  }
}

.x-form-item {
  margin-bottom: 4px;
  outline: none;

  label.x-form-item-label {
    float: left;
    width: 100px;
    padding: 2px 4px;
    line-height: 18px;
    font-size: $font-size !important;
    font-family: $font-family !important;
  }
}

.x-form-element {
  padding-left: 105px;
  // do not use height because textareas can be taller
  min-height: $form-element-height;

  .locked-textfield {
    border: 1px transparent solid;
    background-image: none;
    color: $form-label-color;
  }
}

.x-form-invalid-msg {
  padding: 2px 2px 2px 18px;
  background: extImage('warning') transparent no-repeat 0 2px;
  line-height: $form-input-line-height;
  width: 200px;
  color: #c0272b;
  font-size: $font-size;
  font-family: $font-family;
}

.x-form-label-left label.x-form-item-label {
  text-align: left;
}

.x-form-label-right label.x-form-item-label {
  text-align: right;
}

.x-form-label-top {
  .x-form-item label.x-form-item-label {
    float: none;
    clear: none;
    display: inline;
    position: static;
  }

  .x-form-element {
    padding-left: 0;
    padding-top: 4px;
  }

  .x-form-item {
    padding-bottom: 4px;
  }
}

.x-small-editor .x-form-text {
  height: 21px;
  line-height: $form-input-line-height;
  vertical-align: middle;
}

.ext-strict .x-small-editor .x-form-text {
  height: $form-input-line-height !important;
}

.ext-border-box .x-small-editor .x-form-text {
  height: 20px;
}

.x-small-editor {
  .x-form-select-one {
    height: 20px;
    line-height: $form-input-line-height;
    vertical-align: middle;
  }

  .x-form-num-field {
    text-align: right;
  }

  .x-form-field-wrap .x-form-trigger {
    min-height: $form-text-input-height;
  }
}

.ext-webkit .x-small-editor .x-form-text {
  padding-top: 3px;
}

.x-form-clear {
  clear: both;
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
}

.x-form-clear-left {
  clear: left;
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
}

.ext-border-box .x-form-check-wrap input {
  margin-top: 3px;
}

.x-form-cb-label {
  position: relative;
  top: 2px;
  margin-left: 4px;
  color: $form-label-color;
}

.x-form-column {
  float: left;
  padding: 0;
  margin: 0;
  width: 48%;
  overflow: hidden;
  zoom: 1;
}

.x-form .x-form-btns-ct {
  .x-btn {
    float: right;
    clear: none;
  }

  .x-form-btns td {
    border: 0;
    padding: 0;
  }

  .x-form-btns-right table {
    float: right;
    clear: none;
  }

  .x-form-btns-left table {
    float: left;
    clear: none;
  }

  .x-form-btns-center {
    text-align: center;

    table {
      margin: 0 auto;
    }
  }

  table td.x-form-btn-td {
    padding: 3px;
  }

  .x-btn-focus {
    .x-btn-left {
      background-position: 0 -147px;
    }

    .x-btn-right {
      background-position: 0 -168px;
    }

    .x-btn-center {
      background-position: 0 -189px;
    }
  }

  .x-btn-click {
    .x-btn-center {
      background-position: 0 -126px;
    }

    &.x-btn-right {
      background-position: 0 -84px;
    }

    .x-btn-left {
      background-position: 0 -63px;
    }
  }
}

.x-form-invalid-icon {
  width: $button-icon-font-size;
  height: $button-icon-font-size;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  background: extImage('exclamation') transparent no-repeat 0 2px;
}

.x-hide-label {
  label.x-form-item-label {
    display: none;
  }

  .x-form-element {
    padding-left: 0 !important;
  }
}

.x-form-label-top .x-hide-label label.x-form-item-label {
  display: none;
}

.required-field-marker {
  @include required-marker();
  @include icon-font-position();

  width: $form-input-line-height;
  height: $form-element-height;
  cursor: default; // ensures cursor doesnt turn into an input cursor on edge
}

.x-form-check-group-label {
  border-bottom: 1px solid $panel-border-color;
  color: $text-color;
}

.x-editor .x-form-check-wrap {
  background-color: $form-text-background-color;
}

.x-form-empty-field {
  color: #808080;
}

// div w/ icon font based form trigger icons vs ext's img tag based approach
.smx-form-trigger {
  @include icon-font();
  @include icon-size($form-input-line-height);
  @include icon-color($button-icon-color);

  width: 22px;
  height: 22px;
  background: $button-background-color;
}

.form-note {
  font-family: $font-family;
  font-size: $font-size;
  font-style: italic;
  border: 0 solid;
  margin: 0 10px;
  color: $text-color;
}

.read.readonly-form {
  width: 300px;
}

.readonly-form-item {
  display: block;
  margin-bottom: 4px;
  zoom: 1;
  font-family: $font-family;
  font-size: $font-size;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: normal;

  .readonly-form-item-label {
    clear: left;
    display: block;
    float: left;
    padding: 3px;
    position: relative;
    width: 120px;
    z-index: 2;
    border-style: none;
  }

  .readonly-form-element {
    display: block;
    background-color: $form-input-read-only-background-color;
    position: relative;
    margin: 3px;
    padding: 3px 125px 3px 0;
    border-width: 1px transparent solid;
    margin-left: 120px;
  }
}

.readonly-clear-left {
  clear: left;
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
}

.readonly-table span.col2 {
  width: $panel-field-width;
  vertical-align: top;
  white-space: nowrap;
}

input {
  &.readonly-textfield,
  &.readonly-datefield {
    border: 1px transparent solid;
    background: none;
    text-overflow: ellipsis;
  }
}

.x-combo-list-item {
  color: $form-input-text-color;
}

.x-panel-header-text .right-hint {
  font-weight: normal;
  float: right;
}

form.x-panel-body .advanced {
  margin-top: 0;
  margin-bottom: 0;
}

.smallerLabel .x-form-item-label {
  line-height: 11px;
  padding: 0 3px 0 0;
}

.x-switchable-combo {
  .select,
  .textfield {
    float: left;
  }
}

.x-switchable-combo-body {
  float: left;
}

.x-form-field.x-form-invalid:not(.x-box-layout-ct),
.x-form-invalid .x-form-field,
textarea.x-form-invalid {
  background: $form-invalid-background-image $form-text-invalid-background-color repeat-x
    bottom;
  border-color: $form-text-invalid-border-color;
}

.x-form-inner-invalid,
textarea.x-form-inner-invalid {
  background: $form-invalid-background-image $form-text-background-color repeat-x bottom;
}

body {
  .x-form-field.x-form-invalid:not(.x-box-layout-ct),
  .x-form-invalid .x-form-field,
  textarea.x-form-invalid {
    background-color: $form-text-invalid-background-color;
    border-color: $form-text-invalid-border-color;
  }

  .x-iframe-invalid {
    background: $form-invalid-background-image bottom repeat-x !important;
  }
}

.x-form .x-form-display-field {
  padding-left: 5px;
  padding-top: 5px;

  ul {
    list-style: inside disc;
  }
}

.x-form-radio-group .x-item-disabled {
  filter: none;
}

img.required-field-offset {
  padding-left: 12px;
}

.x-html-editor-wrap iframe {
  border: 1px transparent solid;
}

.highlighted-field {
  background: #ededed;
  border: 1px #c2cbd2 solid;
}

.x-text-resize {
  position: absolute;
  left: -1000px;
  top: -1000px;
  visibility: hidden;
  zoom: 1;
}

.x-left-form-panel .x-form {
  padding: 5px 5px 0;
}

.x-left-form-panel .x-panel-footer .x-toolbar-left {
  padding-left: 119px;
}
