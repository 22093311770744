@import '~fixed-data-table-2/dist/fixed-data-table';

.public_fixedDataTableCell_cellContent {
  padding: 5px;
  color: $text-color;
}

.truncate-cell .public_fixedDataTableCell_wrap1,
.truncate-cell .public_fixedDataTableCell_wrap2,
.truncate-cell .public_fixedDataTableCell_wrap3 {
  display: block;
}

.truncate-cell .public_fixedDataTableCell_cellContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.public_fixedDataTable_main {
  &.busy {
    pointer-events: none;
    filter: brightness(95%);
    transition: 0.1s filter linear;
  }

  &:not(.busy) {
    .public_fixedDataTable_bodyRow {
      cursor: pointer;

      &:hover {
        filter: brightness(92%);
      }
    }
  }

  .public_fixedDataTable_bodyRow {
    &.selected {
      .public_fixedDataTableCell_main {
        background-color: $grid-selected-row-background-color;
      }
    }

    &.read-only {
      opacity: 0.65;
    }
  }
}
