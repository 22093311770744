.modal-content {
  background-color: $window-header-background-color;
  color: $window-header-font-color;

  .form-horizontal,
  .inner-content {
    background: $panel-background-color;

    @if not $panel-flat {
      border: 1px $window-border-color solid;
    }

    margin-bottom: $modal-title-padding;
    margin-left: $modal-title-padding;
    margin-right: $modal-title-padding;
  }

  form .modal-body.large {
    max-height: 80vh;
    overflow-y: auto;

    // avoids vertical scrollbar
    .dialog-field {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .icon-password {
    @include icon-font();
    @include icon-size(32px);
    @include icon('icon-password');

    &::before {
      line-height: inherit;
    }
  }

  h1 {
    font-size: $font-size;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h2 {
    font-size: $font-size;
    margin-top: 0;
    margin-bottom: 26px;
  }
}

.modal-header {
  .modal-title {
    font-size: $font-size;
    font-weight: bold;
    white-space: nowrap;
  }

  button.close {
    background: $desktop-background;
    border-radius: 2px;
    border: $button-border;
    color: $modal-tool-button-icon-color;
    cursor: pointer;
    float: right;
    font-family: monospace;
    padding: 0;
    font-size: 25px;
    line-height: $button-icon-font-size;

    &:hover {
      background: $button-font-color;
      border-color: $button-border-color;
      color: $modal-tool-button-hover-icon-color;
    }
  }
}

.modal-header .modal-title,
.modal-body h1 {
  overflow: hidden;
  text-overflow: ellipsis;
}

.modal {
  background-color: $modal-dialog-background-color;
}
