.x-panel-header {
  overflow: hidden;
  padding: 5px 3px 4px 5px;
  line-height: 15px;
  color: $panel-header-font-color;
  font-weight: bold;

  @if not $panel-flat {
    border: 1px $panel-header-border-color solid;
  }

  @if $panel-header-background != $panel-header-background-color {
    background: $panel-header-background;
  }

  background-color: $panel-header-background-color;

  .x-panel-header-text {
    white-space: nowrap;
  }
}

.x-panel-bwrap {
  overflow: hidden;
  zoom: 1;
  left: 0;
  top: 0;
}

.x-panel-body {
  background-color: $panel-background-color;
  zoom: 1;
}

.x-border-panel {
  @if not $panel-flat {
    border: 1px $panel-border-color solid;
  }

  @if $panel-flat {
    .left-border {
      border-left: 1px $panel-border-color solid;
    }
  }
}

.x-panel-bbar .x-toolbar,
.x-panel-tbar .x-toolbar {
  padding: 5px 4px;
}

.x-panel-tl {
  background: transparent no-repeat 0 0;
  padding-left: 6px;
  border-bottom: 1px solid;

  .x-panel-header {
    padding: 5px 0 4px;
    border: 0;
    background: transparent no-repeat;
    color: $text-color;
    font-size: $font-size;
    font-weight: bold;
  }
}

.x-panel-tl .x-panel-icon,
.x-window-tl .x-panel-icon {
  padding-left: 20px !important;
  background-repeat: no-repeat;
  background-position: 0 4px;
}

.x-panel-inline-icon {
  width: $button-icon-font-size;
  height: $button-icon-font-size;
  background-repeat: no-repeat;
  background-position: 0 0;
  vertical-align: middle;
  margin-right: 4px;
  margin-top: -1px;
  margin-bottom: -1px;
}

.x-panel-tc {
  background: url('~panel/top-bottom.png') transparent repeat-x 0 0;
  overflow: hidden;
}

.x-panel-tr {
  background: transparent no-repeat right 0;
  padding-right: 6px;
}

.x-panel-bc {
  background: url('~panel/top-bottom.png') transparent repeat-x 0 bottom;

  .x-panel-footer {
    padding-bottom: 6px;
  }
}

.x-panel-bl {
  background: transparent no-repeat 0 bottom;
  padding-left: 6px;
  zoom: 1;
}

.x-panel-br {
  background: transparent no-repeat right bottom;
  padding-right: 6px;
  zoom: 1;
}

.x-panel-mc {
  font-size: $font-size;
  background-color: $desktop-background;
  border: 0;
  padding: 0;
  margin: 0;
  padding-top: 6px;

  .x-panel-body {
    background-color: transparent;
    border: 0;
  }
}

.x-panel-ml {
  background: extImage('left-right') $panel-background-color repeat-y 0 0;
  padding-left: 6px;
}

.x-panel-mr {
  background: extImage('left-right') transparent repeat-y right 0;
  padding-right: 6px;
}

.x-panel-nofooter {
  .x-panel-bc,
  .x-window-bc {
    height: 0;
    font-size: 0;
    line-height: 0;
  }
}

.smx3-tab-panel .x-panel-body {
  overflow: auto; // http://youtrack.smxemail.com/issue/SCL-1362
}

.x-panel-collapsed .x-resizable-handle {
  display: none;
}

.x-plain-body,
.x-plain,
.x-plain-bwrap {
  overflow: hidden;
}

.x-plain-bbar .x-toolbar,
.x-plain-tbar .x-toolbar {
  overflow: hidden;
  padding: 2px;
}

// that's modal window tool buttons
.x-tool {
  @include icon-font();
  @include icon-color($modal-tool-button-icon-color);
  @include icon-size($size: $font-size);

  border-radius: $button-border-radius;
  background: $modal-tool-button-background;
  overflow: hidden;
  padding: 1px;
  width: $button-icon-font-size;
  height: $button-icon-font-size;
  line-height: $button-icon-font-size;
  float: right;
  cursor: pointer;
  margin-left: 4px;

  @if not $button-flat {
    border: $button-border;
  }

  &::before {
    margin-left: 2px;
  }
}

.x-panel-ghost {
  background-color: $desktop-background;
  z-index: 12000;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.9;
  border: 1px $panel-border-color solid;

  ul {
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 0;
    line-height: 0;
    display: block;
  }

  * {
    cursor: move !important;
  }

  .x-window-header,
  .x-window-tl,
  .x-window-tr {
    font-weight: bold;
    font-size: $font-size;
    font-family: $font-family;
  }
}

.x-panel-dd-spacer {
  border: 2px $panel-border-color dashed;
}

.x-panel-btns {
  padding: 10px 5px 5px;
  overflow: hidden;

  td.x-toolbar-cell {
    padding: 3px;
  }

  .x-btn-focus {
    .x-btn-left {
      background-position: 0 -147px;
    }

    .x-btn-right {
      background-position: 0 -168px;
    }

    .x-btn-center {
      background-position: 0 -189px;
    }
  }

  .x-btn-over {
    .x-btn-left {
      background-position: 0 -63px;
    }

    .x-btn-right {
      background-position: 0 -84px;
    }

    .x-btn-center {
      background-position: 0 -105px;
    }
  }

  .x-btn-click {
    .x-btn-center {
      background-position: 0 -126px;
    }

    &.x-btn-right {
      background-position: 0 -84px;
    }

    .x-btn-left {
      background-position: 0 -63px;
    }
  }
}

.x-panel-bl,
.x-panel-br,
.x-panel-tl,
.x-panel-tr {
  background-image: url('~panel/corners-sprite.png');
  border-bottom-color: $panel-border-color;
}

.x-tool-collapse-west {
  @include icon('fastleft');
}

.x-tool-expand-west {
  @include icon('fastright');
}

.x-tool-close {
  @include icon('close');
}

.x-tool-minimize {
  @include icon('minus');
}

.x-tool-maximize {
  @include icon('expand');
}

.x-tool-restore {
  @include icon('contract');
}

.x-tool-gear {
  @include icon('gear');
}

.x-tool-prev {
  @include icon('previous');
}

.x-tool-next {
  @include icon('next');
}

.x-tool-pin {
  @include icon('pushpin');
}

.x-tool-unpin {
  @include icon('pushpin');
}

.x-tool-right {
  @include icon('right');
}

.x-tool-left {
  @include icon('left');
}

.x-tool-down {
  @include icon('down');
}

.x-tool-up {
  @include icon('up');
}

.x-tool-refresh {
  @include icon('loop');
}

.x-tool-plus {
  @include icon('plus');
}

.x-tool-minus {
  @include icon('minus');
}

.x-tool-search {
  @include icon('search');
}

.x-tool-save {
  @include icon('record');
}

.x-tool-help {
  @include icon('help');
}

.x-tool-print {
  @include icon('print');
}

.x-tool-expand {
  @include icon('expand');
}

.x-tool-collapse {
  @include icon('collapse');
}

.x-tool-resize {
  @include icon('expand');
}

.x-tool-move {
  @include icon('move');
}

.x-tool-close-over,
.x-tool-collapse-over,
.x-tool-collapse-west-over,
.x-tool-down-over,
.x-tool-expand-over,
.x-tool-expand-west-over,
.x-tool-gear-over,
.x-tool-help-over,
.x-tool-left-over,
.x-tool-maximize-over,
.x-tool-minimize-over,
.x-tool-minus-over,
.x-tool-move-over,
.x-tool-next-over,
.x-tool-pin-over,
.x-tool-plus-over,
.x-tool-prev-over,
.x-tool-print-over,
.x-tool-refresh-over,
.x-tool-resize-over,
.x-tool-restore-over,
.x-tool-right-over,
.x-tool-save-over,
.x-tool-search-over,
.x-tool-toggle-over,
.x-tool-unpin-over,
.x-tool-up-over {
  @include icon-color($modal-tool-button-hover-icon-color);

  background: $button-hover-background;

  @if not $button-flat {
    border: $button-hover-border;
  }
}
