$strength-dec-width: 64px;
$invalid-color: $warning-color !default;
$valid-color: $icon-success-color !default;

.password-group {
  .ReactPasswordStrength {
    border: 0;
    color: $form-input-text-color;
    font-family: inherit;
    font-size: $form-item-font-size;

    .ReactPasswordStrength-input {
      padding-right: $strength-dec-width;
    }

    &[class*=' is-strength-'] .ReactPasswordStrength-strength-bar {
      right: 0;
      top: -2px;
      width: 100%;
    }

    .ReactPasswordStrength-strength-desc {
      padding: 6px;
      width: $strength-dec-width;
    }

    &.is-strength-0 {
      .ReactPasswordStrength-strength-bar {
        background: $invalid-color;
      }

      .ReactPasswordStrength-strength-desc {
        color: $invalid-color;
      }
    }

    // for strength 1 we leave it to the react password component to pick
    // the color which is a brighter, orange-like color like the above
    // invalid color value.

    // since the bootstrap-form element shows a green tick from strength 2
    // onwards, we always show the same green color when valid
    &.is-strength-1,
    &.is-strength-2,
    &.is-strength-3,
    &.is-strength-4 {
      .ReactPasswordStrength-strength-bar {
        background: $valid-color;
      }

      .ReactPasswordStrength-strength-desc {
        color: $valid-color;
      }
    }
  }

  .help-block {
    // this ensures height does not jiggle when text contents have changed, this by reserving
    // at least three lines of text

    min-height: 3 * $form-item-font-size;
  }
}

.form-group {
  input[type='password'] {
    // have it important and use 150% so that lastpass icon is not seen
    // and does not overlap the strength desc

    background-position: 150% 99% !important;
  }
}
