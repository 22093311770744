// inspired by https://github.com/mladenplavsic/css-ripple-effect/blob/master/src/ripple.less
@mixin button-ripple {
  overflow: hidden;
  // do not use translate3d as that wont work with disabled buttons
  transform: translateX(0);

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #000 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:not(.x-item-disabled),
  &.x-btn-click,
  &.x-form-trigger-click {
    &::after {
      transform: scale(0, 0);
      opacity: 0.2;
      transition: 0s;
    }
  }
}
