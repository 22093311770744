.x-toolbar {
  .x-btn {
    margin-right: 5px;

    em {
      &.x-btn-split,
      &.x-btn-arrow,
      &.x-btn-split-bottom,
      &.x-btn-arrow-bottom {
        @include icon-font($where: after);
        @include icon('fastdown', $where: after);
        @include icon-color($button-icon-color, $where: after);
        @include icon-size(8px, $where: after);
      }
    }

    &.x-btn-over,
    &.x-btn-menu-active {
      em {
        &.x-btn-split,
        &.x-btn-arrow,
        &.x-btn-split-bottom,
        &.x-btn-arrow-bottom {
          @include icon-color($button-hover-icon-color, $where: after);
        }
      }
    }
  }
}

.smartrules-icon-bar .x-btn,
.x-panel-tbar .x-toolbar .x-btn,
.x-panel-bbar .x-toolbar .x-btn {
  &:not(.primary) {
    @include secondary-button(true);

    border: 1px $toolbar-button-border-color solid;

    &:not(.x-item-disabled) {
      background: $toolbar-button-background;

      &:hover,
      &.x-btn-over {
        background: $toolbar-button-hover-background;
      }
    }
  }
}
