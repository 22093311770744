.SMX-Dashboard-MailStatistics-Stats {
  clear: left;
  padding-top: 5px;
  color: $text-color;

  .StatsLabel {
    width: 70px;
    float: left;
  }

  .StatsValue {
    width: 60px;
    text-align: right;
    float: left;
  }

  .StatsPercentage {
    width: 40px;
    float: left;
    text-align: right;
    margin-left: 10px;
  }
}

.dashboard {
  div.x-panel-header {
    background: none;
    border-bottom: 0;
  }

  span.x-panel-header-text {
    color: $panel-header-text-color;
    font-size: 16px;
  }

  .x-panel-noborder .x-panel-tbar-noborder .x-toolbar {
    background: none !important;
    border: 0;
  }

  .x-toolbar .ytb-spacer,
  div.x-panel-tbar {
    width: 10px;
  }
}
