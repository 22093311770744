@mixin primary-button {
  &:not(.x-item-disabled) {
    @include icon-color($button-primary-font-color);

    background: $button-primary-background;

    button {
      color: $button-primary-font-color;
    }

    // primary's active state without ripple

    @if not $button-ripple {
      &.x-btn-click,
      &.x-form-trigger-click,
      &.x-btn-menu-active,
      &.x-btn-pressed {
        background-color: $button-primary-active-background-color;

        @if not $button-flat {
          border: $button-primary-active-border;
        }
      }
    }
  }

  &.x-btn-over,
  &.x-form-trigger-over {
    @if $button-primary-hover-background != $button-primary-hover-background-color {
      background: $button-primary-hover-background;
    }

    background-color: $button-primary-hover-background-color;

    button,
    &::before {
      color: $button-primary-hover-font-color;
    }
  }

  &.x-item-disabled {
    background: $button-primary-disabled-background;

    button {
      color: $button-primary-disabled-font-color;
    }

    @if not $button-flat {
      border: $button-primary-disabled-border;
    }
  }
}
