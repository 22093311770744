.no-results {
  line-height: 22px;
  font-size: 11px;
}

.stretch {
  width: 100%;
}

.no-gutter {
  margin-left: 0;
  margin-right: 0;

  > [class*='col-']:not(:first-child),
  > [class*='col-']:not(:last-child) {
    padding-right: 0;
    padding-left: 0;
  }
}

a {
  &:focus {
    outline: none;
  }

  &::-moz-focus-inner {
    border: 0;
  }
}
