.x-accordion-hd {
  padding-top: 4px;
  padding-bottom: 3px;
  border-top: 0;
  background: url('~default/light-hd.png') transparent repeat-x 0 -9px;
  color: $text-color;
  font-weight: normal;
}

.x-layout-collapsed {
  position: absolute;
  left: -10000px;
  top: -10000px;
  visibility: hidden;
  width: 20px;
  height: 20px;
  overflow: hidden;
  border: 1px $panel-border-color solid;
  z-index: 20;
  background-color: $button-primary-disabled-background-color;
}

.ext-border-box .x-layout-collapsed {
  width: 22px;
  height: 22px;
}

.x-layout-collapsed-over {
  cursor: pointer;
  background-color: $button-background-color;
}

.x-layout-collapsed-east .x-layout-collapsed-tools,
.x-layout-collapsed-west .x-layout-collapsed-tools {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
}

.x-layout-split {
  position: absolute;
  height: 5px;
  width: 5px;
  line-height: 1px;
  font-size: 1px;
  z-index: 3;
  background-color: transparent;
}

.x-layout-split-h {
  background: extImage('s');
  background-position: left;
}

.x-layout-split-v {
  background: extImage('s');
  background-position: top;
}

.x-column {
  float: left;
  padding: 0;
  margin: 0;
}

.x-layout-mini {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 35px;
  cursor: pointer;
  opacity: 0.5;
}

.x-layout-collapsed-over .x-layout-mini,
.x-layout-mini-over {
  opacity: 1;
  filter: none;
}

.x-layout-split-east .x-layout-mini,
.x-layout-split-west .x-layout-mini {
  top: 48%;
}

.x-layout-split-north .x-layout-mini,
.x-layout-split-south .x-layout-mini {
  left: 48%;
  height: 5px;
  width: 35px;
}

.x-layout-cmini-east .x-layout-mini,
.x-layout-cmini-west .x-layout-mini {
  top: 48%;
}

.x-layout-cmini-north .x-layout-mini,
.x-layout-cmini-south .x-layout-mini {
  left: 48%;
  height: 5px;
  width: 35px;
}

.x-layout-cmini-east,
.x-layout-cmini-west {
  border: 0;
  width: 5px !important;
  padding: 0;
  background-color: transparent;
}

.x-layout-cmini-north,
.x-layout-cmini-south {
  border: 0;
  height: 5px !important;
  padding: 0;
  background-color: transparent;
}

.x-box-inner,
.x-box-layout-ct,
.x-column-layout-ct,
.x-column,
.x-column-inner {
  overflow: hidden;
}

.x-box-inner {
  position: relative;
  left: 0;
  top: 0;
}

.x-abs-layout-item,
.x-box-item {
  position: absolute;
  left: 0;
  top: 0;
}

.x-layout-cmini-south .x-layout-mini,
.x-layout-split-north .x-layout-mini {
  background: extImage('mini-top');
}

.x-layout-split-east .x-layout-mini,
.x-layout-cmini-west .x-layout-mini {
  background: extImage('mini-right');
}

.x-layout-split-west .x-layout-mini,
.x-layout-cmini-east .x-layout-mini {
  background: extImage('mini-left');
}

.x-layout-split-south .x-layout-mini,
.x-layout-cmini-north .x-layout-mini {
  background: extImage('mini-bottom');
}
