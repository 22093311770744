$notification-window-background: #ffd;

.x-window.notifications {
  .x-window-body {
    background-color: transparent !important;
  }

  .x-window-header,
  .x-window-tl,
  .x-window-tr,
  .x-window-ml,
  .x-window-mr,
  .x-window-bc,
  .x-window-bl,
  .x-window-br {
    background: $notification-window-background !important;
  }

  .x-window-mc {
    border: 0 !important;
  }

  .x-panel-body {
    padding: 8px;
    border: 0 !important;
    background-color: transparent !important;

    li,
    ul {
      list-style: circle !important;
    }
  }
}

.x-btn.notifications {
  background: transparent;
  border: 0;
}
