.x-toolbar {
  display: block;
  padding: 2px;
  position: relative;
  left: 0;
  top: 0;
  overflow: hidden;
  background-color: $toolbar-background-color;

  div,
  input,
  label,
  select,
  span {
    font-weight: normal;
    font-size: $font-size;
    white-space: nowrap;
  }

  .x-item-disabled .x-btn-icon {
    opacity: 0.35;
  }

  div.xtb-text {
    padding: 2px 2px 0;
    line-height: 16px;
    color: $text-color;
  }

  .xtb-sep {
    border-left: 1px $panel-primary-border-color solid;
    display: block;
    height: 16px;
    margin: 0 4px;
  }

  .xtb-spacer {
    width: 3px;
  }
}

.x-toolbar-left {
  width: 100%;
}

.x-tbar-page-number {
  width: 30px;
  height: 14px;
}

.x-paging-info {
  position: absolute;
  top: 5px;
  right: 8px;
  color: $text-color;
}

.x-toolbar-right td {
  text-align: center;

  .x-form-field-trigger-wrap {
    text-align: left;
  }
}

.x-panel-bbar,
.x-panel-tbar,
.x-plain-bbar,
.x-plain-tbar,
.x-tab-panel-bbar,
.x-tab-panel-tbar,
.x-window-bbar,
.x-window-tbar {
  overflow: hidden;
  zoom: 1;
}

.x-toolbar .x-toolbar-more .x-btn-mc em.x-btn-arrow {
  padding-right: 0;

  &::after {
    content: '';
  }
}

div.x-toolbar-no-items {
  color: $button-primary-disabled-font-color !important;
  padding: 5px 10px !important;
}

.ext-border-box .x-toolbar-cell {
  .x-form-text {
    margin-bottom: -1px !important;
  }

  .x-form-field-wrap .x-form-text {
    margin: 0 !important;
  }
}

.x-toolbar-ct {
  width: 100%;
}

.x-toolbar-cell {
  .x-form-checkbox,
  .x-form-radio {
    margin-top: 5px;
  }
}

.direction-toolbar {
  .x-toolbar-cell {
    padding: 5px 0;
    vertical-align: baseline;

    .x-form-radio {
      margin: 0 2px 0 5px;
      height: 18px;
    }

    .x-form-cb-label {
      top: auto;
      margin: 0 5px 0 2px;
      cursor: pointer;
      vertical-align: baseline;
    }
  }

  &.no-border {
    border: 0;
  }

  &.no-background {
    background: none;

    .x-form-radio {
      margin-left: 0;
    }
  }
}

.x-tbar-page-first {
  @include icon('first');
}

.x-tbar-loading {
  @include icon('loop');
}

.x-tbar-page-last {
  @include icon('last');
}

.x-tbar-page-next {
  @include icon('next');
}

.x-tbar-page-prev {
  @include icon('previous');
}

.x-toolbar-load-hint {
  background: url('~grid/loading.gif') no-repeat 3px center;
}

.x-toolbar-right-row .x-form-check-wrap {
  height: $button-icon-font-size;
  line-height: $button-icon-font-size;

  input {
    margin: 2px 0 0;
  }
}

.x-tool-expand-north,
.x-tool-expand-south {
  float: right;
  margin: 3px;
}

.x-tool-expand-east,
.x-tool-expand-west {
  float: none;
  margin: 3px 2px;
}

.x-toolbar.scopebar {
  @if $scopebar-background != $scopebar-background-color {
    background: $scopebar-background;
  }

  background-color: $scopebar-background-color;

  div,
  input,
  label,
  select,
  span,
  td {
    font-size: $font-size;
  }

  .x-btn-mc em.x-btn-split {
    background-image: url('~button/s-arrow-noline.gif');
  }

  .x-btn {
    margin-right: 2px;
  }

  .x-btn-click .x-btn-mc em.x-btn-split,
  .x-btn-menu-active .x-btn-mc em.x-btn-split,
  .x-btn-over .x-btn-mc em.x-btn-split,
  .x-btn-pressed .x-btn-mc em.x-btn-split {
    background-image: url('~button/s-arrow-o.gif');
  }

  .x-btn-mc em.x-btn-split-bottom {
    background-image: url('~button/s-arrow-b-noline.gif');
  }

  .x-btn-click .x-btn-mc em.x-btn-split-bottom,
  .x-btn-menu-active .x-btn-mc em.x-btn-split-bottom,
  .x-btn-over .x-btn-mc em.x-btn-split-bottom,
  .x-btn-pressed .x-btn-mc em.x-btn-split-bottom {
    background-image: url('~button/s-arrow-bo.gif');
  }

  .xtb-sep {
    background-image: url('~grid/grid-split.gif');
  }
}
