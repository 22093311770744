.eventtype-clean {
  background: #cfffe7;
}

.eventtype-spam {
  background: #ffe6bf;
}

.eventtype-threat {
  background: #ffbfc0;
}

.eventtype-whitelist {
  background: #fff;
}

.eventtype-blacklist {
  background: #c1c1c1;
}

.eventtype-unknown {
  background: #ddd;
}

.event-search-form {
  margin-top: 7px;

  .x-form-item {
    margin-bottom: 3px;
  }
}
